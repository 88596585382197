import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
  try {
    const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
    const URL = `${API.baseUrl}/${API.endpoints.answers}`;
    const result = await axios.get(URL + queryString);
    await dispatch(setDataList(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getAll *** ${error}`);
  }
};

export const create = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.answers}`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> create *** ${error}`);
  }
};

export const update = (data, id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.answers}/${id}`;
    await axios.put(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> update *** ${error}`);
  }
};

const answerSlice = createSlice({
  name: 'answer',
  initialState: {
    enabledIntegration: false,
    dataList: [],
    servicesGroupList: [],
    page: 0,
    rowsPerPage: 10,
    total: 0,
    refreshAnswer: false
  },
  reducers: {
    setDataList: (state, action) => {
      state.dataList = action.payload.rows;
      state.total = action.payload.count;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRefreshAnswer: (state, action) => {
      state.refreshAnswer = action.payload;
    },
  }
});

export const { setDataList, setRowsPerPage, setPage, setTotalRows, setRefreshAnswer } =
  answerSlice.actions;

export default answerSlice.reducer;
