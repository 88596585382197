
import { showMessage } from '../store/messageSlice';

export async function errorResponse(dispatch, error, functionName) {
    // console.log('error http ===', error)
    if (error?.response?.data?.message) {
        await dispatch(showMessage({ message: `${error?.response?.data?.message}`, variant: 'error' }));
    } else {
        await dispatch(showMessage({ message: `${error.message}`, variant: 'error' }));
    }
    console.info(`*** REDUX -> ${functionName} ***`)
    return false
}

export async function successResponse(dispatch, result, functionName) {
    if (result.status === 200) {
        return true;
    } else {
        await dispatch(showMessage({ message: `${result.data?.message}`, variant: `${result.data?.status}` }));
        return false;
    }
}