import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import clsx from "clsx";
import { isEmpty } from 'ramda';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  InputAdornment,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormHelperText,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { format, parseISO, parse, isValid } from 'date-fns';

import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomTableHead from '@components/CustomTableHead';

import { showMessage } from '../../../store/messageSlice';
import { saveReceptionInfo } from '../../../store/employeeTrackingSlice';

const useStyles = makeStyles({
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 600,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
});

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  ass_description: '',
};

const schema = yup.object().shape({
  ass_description: yup.string().required('Descripción es requerido')
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function RejectInfoDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit, setValue } = methods;
  const { errors } = formState;
  // --------------------------------------------
  useEffect(() => {
    async function init() {
    }
    init();
  }, [open]);


  const handleAction = async (data) => {
    setLoading(true);
    const response = await dispatch(saveReceptionInfo({
      type: item.type, // reject,
      ass_id: item?.ass_id,
      ass_description: data?.ass_description,
      service_id: item?.fk_seller_service?.sese_service_id, // Randall medina
      user_id: item.user_id,
    }));
    if (response) {
      await dispatch(showMessage({ message: 'Rechazado con exito', variant: 'success' }));
      setLoading(false);
      cleanData();
      handleClose();
      handleRefresh();
    } else {
      setLoading(false);
    }
  }

  const cleanData = () => {
    reset(defaultValues);
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} className={classes.dialog}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
        Información del cliente
        </p>
      </DialogTitle>
      <DialogContent /*sx={{ width: '800px' }}*/ >
        <FormProvider {...methods}>
          {/* ------------ */}
          <div className=''>
            <p className="text-16 font-bold my-2 flex">
              <HelpRoundedIcon style={{ color: '#213462', marginRight: 3 }} /> {' '}
              Movito de rechazo</p>
            <div className="mt-4">
              <div className="grid xl:grid-cols-1 grid-cols-2 gap-1">
                <div className='xl:col-span-1 col-span-2'>
                  <Controller
                    control={control}
                    name="ass_description"
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        styles={{ mt: 1, mb: 2, width: '100%' }}
                        id="ass_description"
                        label="Descripción"
                        multiline
                        rows={2}
                        maxRows={2}
                        error={!!errors.ass_description}
                        helperText={errors?.ass_description?.message}
                        required
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ---------------------- */}
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
              }}
            >
              Cerrar
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
          {/* ------------ */}
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default RejectInfoDialog;
