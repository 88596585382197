import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import CustomLoading from '@components/CustomLoading';
import CustomTableHead from '@components/CustomTableHead';
import CustomTablePagination from '@components/CustomTablePagination';
import CustomButton from '@components/CustomButton';

import CustomDialog from './CustomDialog';
import { getAll, setRowsPerPage, setPage, deleteReferrals } from '../../../store/partnersReferralSlice';
import Swal from 'sweetalert2';

const headCells = [
  {
    id: 'seller_id',
    order: true,
    label: 'Id'
  },
  {
    id: 'seller_name',
    order: true,
    label: 'Nombre Empresa'
  },
  {
    id: 'person_name',
    order: true,
    label: 'Nombres'
  },
  {
    id: 'person_lastname',
    order: true,
    label: 'Apellidos'
  },
  {
    id: 'person_email',
    order: true,
    label: 'Email'
  },
  {
    id: 'person_phone',
    order: true,
    label: 'Telefono'
  },
  {
    id: 'seller_document',
    order: true,
    label: 'Documento'
  },
  {
    id: 'seller_address',
    order: true,
    label: 'Dirección'
  },
  {
    id: 'pr_city_id',
    order: true,
    label: 'Ciudad'
  },
  {
    id: 'pr_country',
    order: true,
    label: 'País'
  },
  {
    id: 'edit',
    order: false,
    label: 'Editar'
  },
  {
    id: 'delete',
    order: false,
    label: 'Borrar'
  },
];

function Index() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authUser = useSelector(({ user }) => user.user);
  const rows = useSelector(({ referrals }) => referrals.dataList);
  const page = useSelector(({ referrals }) => referrals.page);
  const rowsPerPage = useSelector(({ referrals }) => referrals.rowsPerPage);
  const totalRows = useSelector(({ referrals }) => referrals.total);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(0);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'seller_id',
  });
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [itemSelect, setItemSelect] = useState('');

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await dispatch(
        getAll(authUser?.user_id, page, rowsPerPage, order.id, order.direction, {})
      );
      setLoading(false);
    };
    fetch();
  }, [dispatch, page, rowsPerPage, order.id, order.direction]);

  useEffect(() => {
    const fetch = async () => {
      if (rows && rows.length > 0) {
        setItems(rows);
      } else {
        setItems([]);
      }
    };
    fetch();
  }, [rows]);



  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
   * Handles page change in table pagination.
   * @param {number} newPage - New selected page.
   */
  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };
  /**
   * Handles the change in the number of rows per page in the table pagination.
   * @param {Object} event - React event object.
   */
  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  const handleDelete = (referrals) => {
    setLoading(true);
    dispatch(deleteReferrals(referrals.seller_id)).then(r => handleRefresh());
    setLoading(false);
  };

  const toggleDialogCreate = () => {
    setAddDialog(!addDialog);
  };

  const toggleDialogEdit = () => {
    setEditDialog(!editDialog);
  };

  const handleRefresh = async () => {
    await dispatch(
      getAll(authUser?.user_id, page, rowsPerPage, order.id, order.direction, {})
    );
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('Referrals')}</p>
        <CustomButton
          label="Nuevo"
          typebutton={2}
          icon={<AddRoundedIcon fontSize="large" />}
          onClick={toggleDialogCreate}
        />
      </div>
      <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        <div className="bg-red flex justify-end pb-4 px-4">
          <div className="flex">
            <CustomTablePagination
              page={page}
              count={totalRows}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
        {loading ? (
          <CustomLoading />
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items && items.length > 0 ? (
                  items.map((e) => {
                    return (
                      <TableRow
                        hover
                        key={e.seller_id}
                      >
                        <TableCell sx={{ p: '15px 3px' }}>{e.seller_id}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.seller_name}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.persons?.person_name}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.persons?.person_lastname}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.persons?.person_email}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.persons?.person_phone}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.seller_document}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.seller_address}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.fk_city ? `${e.fk_city?.city_name} (${e.fk_city?.fk_state?.state_name})` : null}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.fk_country?.country_name}</TableCell>
                        <TableCell
                          align="center"
                          sx={{ p: '14px 3px' }}
                        >
                          <IconButton
                            onClick={() => {
                              setItemSelect(e);
                              setEditDialog(true)
                            }}
                          >
                            <BorderColorRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ p: '14px 3px' }}
                        >
                          <IconButton
                            onClick={() => {
                              console.log("Borrar: ", e.seller_id);
                              Swal.fire({
                                title: '¿Estás seguro de eliminar este referido?',
                                text: 'Esta acción no se puede deshacer.',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Sí, eliminar',
                                cancelButtonText: 'No, cancelar',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  handleDelete(e);
                                }
                              });
                            }}
                          >
                            <DeleteForeverRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 18 }}
                      colSpan={12}
                    >
                      No se han encontrado datos
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div className="flex justify-end mt-5 px-3">
          <CustomTablePagination
            page={page}
            count={totalRows}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {addDialog && (
        <CustomDialog
          handleClose={toggleDialogCreate}
          handleRefresh={handleRefresh}
          open={addDialog}
          type="create"
        />
      )}
      {editDialog && (
        <CustomDialog
          item={itemSelect}
          open={editDialog}
          handleClose={toggleDialogEdit}
          handleRefresh={handleRefresh}
          type="edit"
        />
      )}
    </div>
  );
}

export default Index;
