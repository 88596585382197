import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import API from "../configs/urlsConfig";

export const startTracking = createAsyncThunk("RangeMe/start", async (_, { rejectWithValue }) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.tracking_rangeme}/start`;
        const response = await axios.post(URL);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || "Error al iniciar el tracking");
    }
});

export const stopTracking = createAsyncThunk("RangeMe/stop", async (_, { rejectWithValue }) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.tracking_rangeme}/stop`;
        const response = await axios.post(URL);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || "Error al detener el tracking");
    }
});

const initialState = {
    dataList: [], // List of trackingRangeMe
    page: 0, // Current page for pagination
    rowsPerPage: 10, // Number of rows per page
    total: 0, // Total number of trackingRangeMe
    RangeMeDropdown: [],
    isTracking: false, // Estado para saber si está en ejecución
};

const trackingRangeMeSlice = createSlice({
    name: 'RangeMe',
    initialState,
    reducers: {
        setRangeMeList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setRangeMeDropdown: (state, action) => {
            state.RangeMeDropdown = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(startTracking.pending, (state) => {
                state.isTracking = true;
            })
            .addCase(startTracking.fulfilled, (state, action) => {
                state.isTracking = false;
                state.dataList = action.payload.data || []; // Guardamos los datos en dataList
            })
            .addCase(startTracking.rejected, (state) => {
                state.isTracking = false;
            })
            .addCase(stopTracking.fulfilled, (state) => {
                state.isTracking = false;
            });
    },
});

export const { setRangeMeList, setRowsPerPage, setPage, setRangeMeDropdown } = trackingRangeMeSlice.actions;

export default trackingRangeMeSlice.reducer;