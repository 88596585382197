import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormHelper,
  FormHelperText,
  IconButton,
  Link
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomTableHead from '@components/CustomTableHead';

import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';

import {
  getServiceFiles,
  createServiceFiles,
  updateServiceFiles,
  deleteServiceFiles,
} from '../../../store/serviceSlice';
import { showMessage } from '../../../store/messageSlice';
import API from '../../../configs/urlsConfig';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
  {
    id: 'sd_name',
    order: false,
    label: 'Nombre'
  },
  {
    id: 'sd_file',
    order: false,
    label: 'Archivo',
  },
  {
    id: 'sd_status',
    order: false,
    label: 'Estado',
  },
  {
    id: 'action',
    order: false,
    label: 'Action',
  },
];

const defaultValues = {
  sd_name: '',
  sd_file: null,
  sd_status: null,
};

const schema = yup.object().shape({
  sd_name: yup.string().required('Nombre es requerido'),
  sd_file: yup.mixed().required('Archivo es requerido'),
  sd_status: yup.object().required('Estado es requerida'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function UploadFileTab(props) {
  const { mainId } = props;
  const dispatch = useDispatch();
  //-------------------------------------------
  const files = useSelector(({ service }) => service.files);
  //-------------------------------------------
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingImages, setLoadingImages] = useState([]);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'sd_id',
  });

  const [items, setItems] = useState([]);
  const [changeDataFile, setChangeDataFile] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  //-------------------------------------------
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema), // Resolver para la validación
  });

  const { reset, handleSubmit, control, setValue, formState } = methods;
  const { errors } = formState;
  //-------------------------------------------
  useEffect(() => {
    const fetch = async () => {
      if (files) {
        setItems(files);
      }
    };
    fetch();
  }, [files]);

  useEffect(() => {
    async function fetch() {
      if (changeDataFile, mainId) {
        await dispatch(getServiceFiles(mainId));
        setChangeDataFile(false)
      }
    }
    fetch();
  }, [changeDataFile, mainId]);

  useEffect(() => {
    const fetch = async () => {
      if (selectRow) {
        reset({
          ...selectRow,
          // sd_file: { file: { name: selectRow.sd_file } },
          sd_file: null,
          sd_status: {
            label: selectRow.sd_status === 0 ? "Inactivo" : "Activo",
            value: selectRow.sd_status,
          },
        });
      }
    };
    fetch();
  }, [reset, selectRow]);

  /**
  * Function to clean the information
  */
  const cleanData = async () => {
    setSelectRow(null);
    reset(defaultValues);
  };

  /**
* Handles the table sort request.
* @param {string} property - Property to sort the table by.
*/
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };
  // --------------------------------------------
  const statusOptions = [
    { label: 'Inactivo', value: 0 },
    { label: 'Activo', value: 1 }
  ];
  // --------------------------------------------
  const handleUploadChange = async (eventFile) => {
    const file = eventFile[0];
    if (!file) {
      return;
    }
    const formatType =
      file.type === 'application/pdf'
        ? 'pdf'
        : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ? 'docx'
          : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ? 'xlsx'
            : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
              ? 'xlspptxx'
              : file.type === 'image/png'
                ? 'png'
                : 'jpeg';
    const data = {
      file,
      hash_archivo: `${md5(Date.now())}.${formatType}`,
    };
    setValue('sd_file', data);
    errors.sd_file = false;
    // ----------------------------------------------------------------------
    // const reader = new FileReader();
    // reader.readAsBinaryString(file);
    // reader.onload = async () => {
    //   const formatType =
    //     file.type === 'application/pdf'
    //       ? 'pdf'
    //       : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    //         ? 'docx'
    //         : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    //           ? 'xlsx'
    //           : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    //             ? 'xlspptxx'
    //             : file.type === 'image/png'
    //               ? 'png'
    //               : 'jpeg';
    //   const data = {
    //     file,
    //     name_archivo: `${md5(Date.now())}.${formatType}`,
    //     url_base64: `data:${file.type};base64,${btoa(reader.result)}`,
    //   };
    //   setValue('sd_file', data);
    //   errors.sd_file = false;
    // };
    // reader.onerror = function (error) {
    //   console.log('error on load image', error);
    // };
    // ----------------------------------------------------------------------
  };

  const handleRemoveFile = () => {
    cleanData();
  };

  const handleUploadFile = async (data) => {
    setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', data.sd_file.file);
    formData.append('hash_archivo', data.sd_file.hash_archivo);
    formData.append('sd_name', data.sd_name);
    formData.append('sd_service_id', mainId);
    formData.append('sd_status', data.sd_status.value);

    const response = await dispatch(createServiceFiles(formData));
    if (response) {
      await dispatch(showMessage({ message: 'Archivo cargardo con exito', variant: 'success' }));
      cleanData();
      setChangeDataFile(true)
    }
    setLoadingFile(false);
  }

  const handleActivityUpdate = async (data) => {
    setLoadingFile(true);

    const formData = new FormData();
    formData.append('file', data.sd_file.file);
    formData.append('hash_archivo', data.sd_file.hash_archivo);
    formData.append('sd_name', data.sd_name);
    formData.append('sd_service_id', mainId);
    formData.append('sd_status', data.sd_status.value);

    const response = await dispatch(updateServiceFiles(formData, data.sd_id));
    if (response) {
      await dispatch(showMessage({ message: 'Actividad actualizada con exito', variant: 'success' }));
      cleanData();
      setChangeDataFile(true)
    }
    setLoadingFile(false);
  }

  const handleDeleteFile = async (row) => {
    setLoadingImages((prevLoading) => [...prevLoading, row.sd_id]);
    const response = await dispatch(deleteServiceFiles({ sd_id: row.sd_id }))
    if (response) {
      await dispatch(showMessage({ message: 'Archivo eliminado con exito', variant: 'success' }));
      await dispatch(getServiceFiles(mainId));
    }
  }

  return (
    <div sx={{ width: '600px' }}>
      {/* ---------------------- */}
      <div className=''>
        {/* <p className="text-16 font-bold my-2">Archivos</p> */}
        <div className="mt-4">
          <FormProvider {...methods}>
            <div className="mt-4 flex flex-col">
              <div className='xl:col-span-1 col-span-2'>
                <p color="#262940" className="text-16 mb-2">
                  Adjuntar documento de soporte{' '}
                  <span style={{ color: '#B7BECD' }}>(jpg, jpeg, png, docx, xlsx, pptx, pdf):</span>
                </p>
                <Controller
                  name="sd_file"
                  control={control}
                  render={({ field }) => (
                    <CustomUploadFile
                      {...field}
                      id="sd_file"
                      handleUploadChange={(e) => handleUploadChange(e)}
                      handleRemoveFile={(e) => handleRemoveFile(e)}
                      accept={{
                        'application/pdf': ['.pdf'],
                        'image/*': ['.jpg', '.jpeg', '.png'],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                          '.doc',
                          '.docx',
                        ],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                      }}
                      required={!!errors.sd_file}
                    />
                  )}
                />
                <div className=''>
                  <FormControl error={!!errors.sd_file} className="w-full">
                    <FormHelperText style={{ marginLeft: 0 }}>{errors?.sd_file?.message}</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-3">
                <Controller
                  name="sd_name"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      styles={{ mt: 0, mb: 2, width: '100%' }}
                      id="sd_name"
                      label="Nombre"
                      error={!!errors.sd_name}
                      helperText={errors?.sd_name?.message}
                      required
                    />
                  )}
                />
                <Controller
                  name="sd_status"
                  control={control}
                  render={({ field: { onChange, value, onBlur, ref } }) => (
                    <CustomAutocomplete
                      styles={{ mt: 0, mb: 2, width: '100%' }}
                      label="Estado"
                      id="sd_status"
                      value={value || null}
                      options={statusOptions}
                      onChange={(event, newValue) => {
                        onChange(event);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.key}>
                          {option.label}
                        </li>
                      )}
                      error={!!errors.sd_status}
                      helperText={errors?.sd_status?.message}
                      required
                    />
                  )}
                />
              </div>
              <div className="flex justify-end items-center">
                {loadingFile ? (
                  <CircularProgress
                    style={{ color: '#4575D5', marginRight: 4 }}
                    size={24}
                  />
                ) : (
                  <div className='flex items-center'>
                    <CustomButton
                      label={selectRow === null ? "Subir" : "Actualizar"}
                      styles={{ mt: 1 }}
                      typebutton={2}
                      onClick={handleSubmit(selectRow === null ? handleUploadFile : handleActivityUpdate)}
                    />
                    {selectRow && (
                      <p
                        className="mx-4 underline text-base text-gray-600 cursor-pointer"
                        onClick={() => {
                          cleanData();
                        }}
                      >
                        Cancel
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </FormProvider>
          <TableContainer>
            <Table size="small" className="simple mt-4">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items.length > 0 ? items.map((row) => (
                  <TableRow
                    key={row.sd_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.sd_name}</TableCell>
                    <TableCell sx={{ p: '15px 3px' }}>
                      <div
                        style={{ color: row.sd_status === 1 ? "#11AE92" : "#EB5757" }}
                      >
                        {row.sd_status === 1 ? "Activo" : "Inactivo"}
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ p: '14px 3px' }}
                    >
                      {row.sd_file && (
                        <Link
                          target="_blank"
                          href={`${API.url_ftp}/servicios/${row.sd_file}`}
                          color="#213462"
                          className="text-12 font-bold cursor-pointer"
                        >
                          {/* row.sd_file */}
                          Ver
                        </Link>
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ p: '14px 3px' }}
                    >
                      {loadingImages.includes(row.sd_id) ? (
                        <CircularProgress
                          style={{ color: '#4575D5', marginRight: 4 }}
                          size={24}
                        />
                      ) :
                        <div>
                          <IconButton
                            onClick={async () => {
                              setSelectRow(row)
                            }}
                          >
                            <BorderColorRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={async () => {
                              handleDeleteFile(row)
                            }}
                          >
                            <DeleteSweepRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 12 }}
                      colSpan={5}
                    >
                      No se han encontrado archivos
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* ---------------------- */}
    </div>
  );
}

export default UploadFileTab;
