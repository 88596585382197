import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
import { format, parseISO } from 'date-fns'

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import CloudCircleRoundedIcon from '@mui/icons-material/CloudCircleRounded';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';

import CustomLoading from '@components/CustomLoading';
import CustomTableHead from '@components/CustomTableHead';
import CustomTablePagination from '@components/CustomTablePagination';
import CustomButton from '@components/CustomButton';

import CustomDialog from './CustomDialog';
import { getServicesById, setRowsPerPageTwo, setPageTwo, setCleanSellerServ } from '../../../store/sellerServicesSlice';

const ExpandIcon = styled(ArrowDropDownCircleRoundedIcon)(({ theme, expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const headCells = [
  {
    id: 'sese_id',
    order: true,
    label: 'Tipo'
  },
  {
    id: 'sese_service_id',
    order: true,
    label: 'Descripción'
  },
  {
    id: 'sese_payment_date',
    order: true,
    label: 'Fecha'
  },
  {
    id: 'sese_service_type_id',
    order: true,
    label: 'Tipo de servicio',
    align: 'center'
  },
  {
    id: 'sese_payment_value',
    order: true,
    label: 'Valor',
    width: '8%'
  },
  {
    id: 'action',
    order: false,
    label: 'Ejecución'
  },
];

function Index() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authUser = useSelector(({ user }) => user.user);
  const rows = useSelector(({ sellerServ }) => sellerServ.dataById);
  const page = useSelector(({ sellerServ }) => sellerServ.pageTwo);
  const rowsPerPage = useSelector(({ sellerServ }) => sellerServ.rowsPerPageTwo);
  const totalRows = useSelector(({ sellerServ }) => sellerServ.totalTwo);
  const totalService = useSelector(({ sellerServ }) => sellerServ.totalService);
  const totalSubscription = useSelector(({ sellerServ }) => sellerServ.totalSubscription);
  const totalPayment = useSelector(({ sellerServ }) => sellerServ.totalPayment);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(0);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'sese_id',
  });
  const [serviceDialog, setServiceDialog] = useState(false);
  const [itemService, setItemService] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);

  const handleExpandClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await dispatch(
        getServicesById(authUser?.user_id, page, rowsPerPage, order.id, order.direction, {})
      );
      setLoading(false);
    };
    fetch();
  }, [dispatch, page, rowsPerPage, order.id, order.direction]);

  useEffect(() => {
    const fetch = async () => {
      if (rows && rows.length > 0) {
        setItems(rows);
      }
    };
    fetch();
  }, [rows]);

  useEffect(() => {
    return async () => {
      await dispatch(setCleanSellerServ())
    }
  }, []);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
  * Handles page change in table pagination.
  * @param {number} newPage - New selected page.
  */
  const handleChangePage = (event, newPage) => {
    dispatch(setPageTwo(newPage));
  };
  /**
   * Handles the change in the number of rows per page in the table pagination.
   * @param {Object} event - React event object.
   */
  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPageTwo(parseInt(event.target.value, 10)));
    dispatch(setPageTwo(0));
  };

  const toggleDialogClose = () => {
    setServiceDialog(!serviceDialog);
  };

  const handleRefresh = async () => {
    await dispatch(
      getServicesById(authUser?.user_id, page, rowsPerPage, order.id, order.direction, {})
    );
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('my_purchases')}</p>
        {/* <CustomButton
          label="Nuevo"
          typebutton={2}
          icon={<AddRoundedIcon fontSize="large" />}
          onClick={toggleDialogCreate}
        /> */}
      </div>
      <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        <div className="bg-red flex justify-end pb-4 px-4">
          <div className="flex">
            <CustomTablePagination
              page={page}
              count={totalRows}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
        {loading ? (
          <CustomLoading />
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items && items.length > 0 ? (
                  <>
                    {items.map((e, index) => {
                      if (e.sese_id) {
                        return (
                          <TableRow
                            hover
                            key={e.sese_id}
                          >
                            <TableCell sx={{ p: '15px 3px' }}>
                              <CloudCircleRoundedIcon style={{ color: '#213462' }} />&nbsp;
                              Servicio
                            </TableCell>
                            <TableCell sx={{ p: '15px 3px' }}>{e.fk_service?.service_name}</TableCell>
                            <TableCell sx={{ p: '15px 3px' }}>{format(parseISO(`${e.sese_payment_date}`), "dd-MM-yyyy")}{' '}</TableCell>
                            <TableCell sx={{ p: '15px 3px', textAlign: 'center' }}>{e.fk_service_type?.sety_name}</TableCell>
                            <TableCell sx={{ p: '15px 3px' }}>{currency(e.sese_payment_value, { symbol: "$", precision: 0 }).format()}</TableCell>
                            <TableCell
                              align="left"
                              sx={{ p: '14px 3px' }}
                            >
                              {(e.sese_sku !== '100207001' && e.sese_sku !== '100207002' && e.sese_sku !== '100207003')  && (
                                <IconButton
                                  onClick={() => {
                                    setItemService(e);
                                    setServiceDialog(true)
                                  }}
                                >
                                  <ManageHistoryRoundedIcon
                                    style={{ color: '#213462' }}
                                  />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      }
                      if (e.sesu_id) {
                        return (
                          <React.Fragment key={e.sesu_id}>
                            <TableRow
                              hover
                              key={e.sesu_id}
                              onClick={() => e.seller_services.length > 0 && handleExpandClick(e.sesu_id)}
                              style={{ background: expandedRow === e.sesu_id ? '#f5f5f5' : '', cursor: 'pointer' }}
                            >
                              <TableCell sx={{ p: '15px 2px' }}>
                                <SummarizeRoundedIcon style={{ color: '#213462' }} />&nbsp;
                                Suscripción
                              </TableCell>
                              <TableCell sx={{ p: '15px 3px' }}>{e.fk_subscription?.subscription_name}</TableCell>
                              <TableCell sx={{ p: '15px 3px' }}>{format(parseISO(`${e.sesu_close_date}`), "dd-MM-yyyy")}{' '}</TableCell>
                              <TableCell sx={{ p: '15px 3px', textAlign: 'center' }}>Suscripción</TableCell>
                              <TableCell sx={{ p: '15px 3px' }}>
                                {currency(e.sesu_total_value, { symbol: "$", precision: 0 }).format()}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ p: '14px 3px' }}
                              >
                                {e.seller_services.length > 0 && (
                                  <IconButton onClick={(event) => {
                                    event.stopPropagation(); // Evita que se dispare el evento onClick de la fila
                                    handleExpandClick(e.sesu_id);
                                  }}>
                                    <ExpandIcon style={{ color: '#213462' }} expanded={expandedRow === e.sesu_id} />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                            {expandedRow === e.sesu_id && (
                              <TableRow>
                                <TableCell colSpan={7} style={{ padding: 0 }}>
                                  <Accordion expanded={expandedRow === e.sesu_id} elevation={0}>
                                    <AccordionDetails>
                                      <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        {e.seller_services.map((t) => (
                                          <TableRow
                                            hover
                                            key={t.sese_id}
                                          >
                                            <TableCell style={{ border: e.seller_services[e.seller_services.length - 1].sese_id === t.sese_id ? 0 : '' }} sx={{ p: '15px 10px' }}>{t.fk_service?.service_name}</TableCell>
                                            <TableCell style={{ border: e.seller_services[e.seller_services.length - 1].sese_id === t.sese_id ? 0 : '' }} sx={{ p: '15px 3px' }}>{format(parseISO(`${t.sese_payment_date}`), "dd-MM-yyyy")}{' '}</TableCell>
                                            <TableCell style={{ border: e.seller_services[e.seller_services.length - 1].sese_id === t.sese_id ? 0 : '' }} sx={{ p: '15px 3px' }}>{currency(t.sese_payment_value, { symbol: "$", precision: 0 }).format()}</TableCell>
                                            <TableCell style={{ border: e.seller_services[e.seller_services.length - 1].sese_id === t.sese_id ? 0 : '' }} sx={{ p: '15px 3px' }}>{t.fk_service_type?.sety_name}</TableCell>
                                            <TableCell
                                              align="left"
                                              sx={{ p: '14px 3px' }}
                                              style={{ border: e.seller_services[e.seller_services.length - 1].sese_id === t.sese_id ? 0 : '' }}
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  setItemService(t);
                                                  setServiceDialog(true)
                                                }}
                                              >
                                                <ManageHistoryRoundedIcon
                                                  style={{ color: '#213462' }}
                                                />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        );
                      }
                    })}
                    {/* ----------------------------------------- */}
                    {
                      <>
                        <TableRow>
                          <TableCell sx={{ p: '15px 3px' }} colSpan={3}></TableCell>
                          <TableCell sx={{ p: '15px 3px' }} colSpan={2}>
                            <div className=''>
                              <div className='font-medium mb-3'>Total Servicios:</div>
                              <div className='font-medium mb-3'>Total Suscripciones:</div>
                              <div className='font-bold mb-3 text-[15px]'>Total Compras:</div>
                            </div>
                          </TableCell>
                          <TableCell sx={{ p: '15px 3px' }}>
                            <div className=''>
                              <div className='font-medium mb-3'>{currency(totalService, { symbol: "US$", precision: 0 }).format()}</div>
                              <div className='font-medium mb-3'>{currency(totalSubscription, { symbol: "US$", precision: 0 }).format()}</div>
                              <div className='font-bold mb-3 text-[15px]'>{currency(totalPayment, { symbol: "US$", precision: 0 }).format()}</div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    }
                  </>

                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 18 }}
                      colSpan={10}
                    >
                      No se han encontrado datos
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div className="flex justify-end mt-5 px-3">
          <CustomTablePagination
            page={page}
            count={totalRows}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {serviceDialog && (
        <CustomDialog
          item={itemService}
          open={serviceDialog}
          handleClose={toggleDialogClose}
          handleRefresh={handleRefresh}
          type=""
        />
      )}
    </div>
  );
}

export default Index;
