import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';

import {
  create,
  update,
} from '../../../store/partnersReferralSlice';
import { showMessage } from '../../../store/messageSlice';
import { getCountries, getStateId, setCleanState } from "../../../store/stateSlice";
import { getDocumentTypes } from "../../../store/documentTypeSlice";
import { getCities, setCleanCity } from "../../../store/citySlice";

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  pr_name: '',
  pr_lastname: '',
  pr_email: '',
  pr_phone: '',

  pr_company_name: '',
  pr_document: '',
  pr_address: '',
  pr_link_video: null,
  pr_url: null,
  pr_status: '',

  pr_document_type_id: null,
  pr_city_id: null,
  pr_country_id: null,

  pr_document_type_name: null,
  pr_country_name: null,
};

const schema = (type) => yup.object().shape({
  pr_name: yup.string().required('Nombres es requerido'),
  pr_lastname: yup.string().required('Apellidos es requerido'),
  pr_company_name: yup.string().required('Nombre de la empresa es requerido'),
  // pr_cargo: yup.string().required('Cargo es requerido'),
  pr_email: yup.string().email('Email no válido').required('Email es requerido'),
  pr_country_id: yup.object().required('País es requerido'),
  // pr_city_id: yup.object().required('Ciudad es requerido'),
  pr_document_type_id: yup.object().required('Tipo de documento es requerido'),
  pr_document: yup
    .string()
    .matches(/^\d{7}$|^\d{10}$/, 'Documento debe tener 7 o 10 dígitos')
    .required('Documento es requerido'),
  // pr_address: yup.string().required('Direccion es requerida'),

  pr_phone: yup
    .string()
    .notRequired() // No requerido por defecto
    .matches(/^[0-9]+$/, 'Celular debe contener solo números')
    .min(12, 'Celular debe tener al menos 12 dígitos incluyendo el indicativo del país')
    .max(15, 'Celular debe tener un máximo de 15 dígitos incluyendo el indicativo del país')
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner referral being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();

  const documents = useSelector(({ docType }) => docType.documentsList);
  const cities = useSelector(({ city }) => city.citiesList);
  const countries = useSelector(({ state }) => state.countriesList);
  const states = useSelector(({ state }) => state.statesList);
  const authUser = useSelector(({ user }) => user.user);

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema(type)), // Pasar el 'type' al esquema
  });
  const { reset, setValue, getValues, control, onChange, formState, handleSubmit, watch } = methods;
  const { errors } = formState;
  const form = watch();

  const initDialog = useCallback(async () => {
    reset({
      ...item,
      pr_name: item?.persons?.person_name,
      pr_lastname: item?.persons?.person_lastname,
      pr_email: item?.persons?.person_email,
      pr_phone: item?.persons?.person_phone,

      pr_company_name: item?.seller_name,
      pr_document: item?.seller_document,
      pr_address: item?.seller_address,
      pr_link_video: item?.seller_link_video,
      pr_url: item?.seller_url,
      pr_status: item?.seller_state,

      pr_document_type_id: item.seller_document_type_id === null || item.seller_document_type_id === '' ? null : {
        label: item.fk_document_type?.document_type,
        value: item.seller_document_type_id,
      },
      pr_city_id: item.seller_city_id === null || item.seller_city_id === '' ? null : {
        label: `${item.fk_city?.city_name}`,
        value: item.seller_city_id,
      },
      pr_state_id: item.seller_city_id === null || item.seller_city_id === '' ? null : {
        label: item.fk_city?.fk_state?.state_name,
        value: item.fk_city?.fk_state?.state_id,
      },
      pr_country_id: item.seller_country_id === null || item.seller_country_id === '' ? null : {
        label: item.fk_country?.country_name,
        value: item.fk_country?.country_id,
      },
    });
    if (item.seller_country_id !== null) {
      await dispatch(getStateId(item.fk_country?.country_id)); // country_id
    }
    if (item.seller_city_id !== null) {
      await dispatch(getCities(item.fk_city?.fk_state?.state_id)); // state_id
    }
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        console.log("Iniciando edición con:", item);
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getDocumentTypes());
      await dispatch(getCountries());
    }
    init();
  }, []);

  /**
  * Function to clean the information
  */
  const cleanData = async () => {
    reset(defaultValues);
    await dispatch(setCleanState())
    await dispatch(setCleanCity())
  };

  // --------------------------------------------

  const optionDocuments = documents
    ? documents.map((e) => ({
      value: e.document_id,
      label: e.document_type,
    }))
    : [];

  const optionCities = cities
    ? cities.map((e) => ({
      value: e.city_id,
      label: e.fullName,
    }))
    : [];

  const optionStates = states
    ? states.map((e) => ({
      value: e.state_id,
      label: e.state_name
    }))
    : [];

  const optionCountries = countries
    ? countries.map((e) => ({
      value: e.country_id,
      label: e.country_name
    }))
    : [];

  // --------------------------------------------

  /**
   * Function to send the new partner referrals information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create({
        ...data,
        user_id: authUser?.user_id,
        pr_country_id: data.pr_country_id.value,
        pr_city_id: data.pr_city_id ? data.pr_city_id.value : null,
        pr_document_type_id: data.pr_document_type_id.value,
      }));
      if (response) {
        await dispatch(showMessage({ message: 'Partner Referral creado', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      }
      else {
        setLoading(false);
      }
    } else {
      const response = await dispatch(update({
        ...data,
        pr_country_id: data.pr_country_id.value,
        pr_city_id: data.pr_city_id ? data.pr_city_id.value : null,
        pr_document_type_id: data.pr_document_type_id.value,
      },
        item.seller_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Partner Referral actualizado', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      }
      else {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Partner Referral
        </p>
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <p className="text-16 font-bold my-2">Datos Empresa</p>
            <Controller
              name="pr_company_name"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="pr_company_name"
                  label="Nombre de la empresa"
                  error={!!errors.pr_company_name}
                  helperText={errors?.pr_company_name?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_country_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="País"
                  id="pr_country_id"
                  value={value || null}
                  options={optionCountries}
                  onChange={async (event, newValue) => {
                    onChange(event);
                    setValue("pr_state_id", null, { shouldValidate: true });
                    setValue("pr_city_id", null, { shouldValidate: true });
                    setValue("pr_country_name", event.label);
                    await dispatch(getStateId(event?.value));
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.pr_country_id}
                  helperText={errors?.pr_country_id?.message}
                  required
                  disabled={form.pr_status === 1 || form.pr_status === 0 ? true : false}
                />
              )}
            />
            <Controller
              name="pr_state_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Departamento"
                  id="pr_state_id"
                  value={value || null}
                  options={optionStates}
                  onChange={async (event, newValue) => {
                    setValue("pr_city_id", null, { shouldValidate: true });
                    onChange(event);
                    if (event) {
                      await dispatch(getCities(event?.value));
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.pr_state_id}
                  helperText={errors?.pr_state_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_city_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Ciudad"
                  id="pr_city_id"
                  value={value || null}
                  options={optionCities}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.pr_city_id}
                  helperText={errors?.pr_city_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_document_type_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Tipo de documento"
                  id="pr_document_type_id"
                  value={value || null}
                  options={optionDocuments}
                  onChange={(event, newValue) => {
                    setValue("pr_document_type_name", event.label);
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.pr_document_type_id}
                  helperText={errors?.pr_document_type_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_document"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mb: 2, width: '100%' }}
                  id="pr_document"
                  label="Documento"
                  error={!!errors.pr_document}
                  helperText={errors?.pr_document?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_address"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="pr_address"
                  label="Direccion"
                  placeholder=""
                  error={!!errors.pr_address}
                  helperText={errors?.pr_address?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_link_video"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="pr_link_video"
                  label="Link de video"
                  // value={form.seller_link_video}
                  // onChange={onChangeText('seller_link_video')}
                  error={!!errors.pr_link_video}
                  helperText={errors?.pr_link_video?.message}
                // required
                />
              )}
            />
            <Controller
              name="pr_url"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="pr_url"
                  label="Url pagina"
                  // value={form.cli_celular}
                  error={!!errors.pr_url}
                  helperText={errors?.pr_url?.message}
                // required
                />
              )}
            />
          </div>
          <div className=''>
            <p className="text-16 font-bold my-2">Datos personales</p>
            <Controller
              name="pr_name"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="pr_name"
                  label="Nombres"
                  error={!!errors.pr_name}
                  helperText={errors?.pr_name?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_lastname"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="pr_lastname"
                  label="Apellidos"
                  error={!!errors.pr_lastname}
                  helperText={errors?.pr_lastname?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_email"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="pr_email"
                  label="Email"
                  placeholder="your@email.com"
                  error={!!errors.pr_email}
                  helperText={errors?.pr_email?.message}
                  required
                />
              )}
            />
            <Controller
              name="pr_phone"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="pr_phone"
                  label="Celular"
                  error={!!errors.pr_phone}
                  helperText={errors?.pr_phone?.message}
                  required
                />
              )}
            />
          </div>
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
