import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { showMessage } from './messageSlice';
import { errorResponse, successResponse } from '../utils/httpResponse'

export const getReferralsDropdown = () => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.partners_referrals}/dropdown`;
        const result = await axios.get(URL);
        await dispatch(setReferralsDropdown(result.data));
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> setReferralsDropdown *** ${error}`);
    }
};

export const getAll = (userId, page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.partners_referrals}/user/${userId}`;
        const result = await axios.get(URL + queryString);
        await dispatch(setReferralsList(result.data));
        return result;
    } catch (error) {
        // throw new Error(`*** REDUX -> getAll *** ${error}`);
        return await errorResponse(dispatch, error, 'getAll')
    }
};

export const create = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.partners_referrals}`;
        const result = await axios.post(URL, data);
        return await successResponse(dispatch, result)
    } catch (error) {
        // throw new Error(`*** REDUX -> create *** ${error}`);
        return await errorResponse(dispatch, error, 'create')
    }
};

export const update = (data, id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.partners_referrals}/${id}`;
        const result = await axios.put(URL, data);
        return await successResponse(dispatch, result)
    } catch (error) {
        // throw new Error(`*** REDUX -> update *** ${error}`);
        return await errorResponse(dispatch, error, 'update')
    }
};

export const deleteReferrals = (id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.partners_referrals}/${id}`;
        await axios.delete(URL, id);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> delete *** ${error}`);
    }
};

const initialState = {
    dataList: [],
    page: 0,
    rowsPerPage: 10,
    total: 0,
    referralsDropdown: [],
};

const partnersReferralSlice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {
        setReferralsList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setReferralsDropdown: (state, action) => {
            state.referralsDropdown = action.payload;
        },
    },
});

export const { setReferralsList, setRowsPerPage, setPage, setReferralsDropdown } = partnersReferralSlice.actions;

export default partnersReferralSlice.reducer;