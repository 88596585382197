import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import clsx from "clsx";
import { format, parseISO } from 'date-fns'
import md5 from 'md5';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Link,
  FormControl,
  FormHelperText,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FilterRoundedIcon from '@mui/icons-material/FilterRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import CustomTableHead from '@components/CustomTableHead';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomActionDialog from '@components/CustomActionDialog';

import { showMessage } from '../../../store/messageSlice';
import { createActivityFile, getActivityFile, deleteActivityFile, setCleanTrackUp } from '../../../store/sellerServicesSlice';
import API from '../../../configs/urlsConfig';

const useStyles = makeStyles({
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 800,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
});

const headCells = [
  {
    id: 'acfi_name',
    order: false,
    label: 'Nombre'
  },
  {
    id: 'acfi_file',
    order: false,
    label: 'Archivo',
  },
  {
    id: 'action',
    order: false,
    label: 'Action',
  },
];

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  acfi_name: '',
  acfi_file: null,
};

const schema = yup.object().shape({
  acfi_name: yup.string().required('Nombre es requerido'),
  // acfi_file: yup.object().required('Documento es requerido').typeError('Documento es requerido'),
  acfi_file: yup.mixed().required('Archivo es requerido')
  // file: yup
  //   .mixed()
  //   .required("You must upload a file.") // Verificar si el archivo está presente
  //   .test(
  //     "fileSize",
  //     "The file is too large. Max size is 5MB.",
  //     (value) => !value || (value && value.size <= FILE_SIZE_LIMIT) // Verificar el tamaño del archivo
  //   )
  //   .test(
  //     "fileFormat",
  //     "Unsupported file format. Allowed formats: jpeg, png, pdf.",
  //     (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type)) // Verificar el tipo de archivo
  //   ),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function UploadFileDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const activityFiles = useSelector(({ sellerServ }) => sellerServ.activityFiles);
  const authUser = useSelector(({ user }) => user.user);

  const [items, setItems] = useState([]);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'acfi_id',
  });
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingImages, setLoadingImages] = useState([]);
  const [dialogAction, setDialogAction] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, setValue, getValues, control, onChange, formState, handleSubmit } = methods;
  const { errors } = formState;

  useEffect(() => {
    const fetch = async () => {
      if (activityFiles) {
        setItems(activityFiles);
      } else {
        setItems([])
      }
    };
    fetch();
  }, [activityFiles]);

  useEffect(() => {
    async function init() {
      await dispatch(getActivityFile(item?.ass_id, item?.ass_step));
    }
    init();

    return async () => {
      await dispatch(setCleanTrackUp())
    }
  }, [open]);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
* Function to clean the information
*/
  const cleanData = () => {
    reset(defaultValues);
  };

  const handleUploadChange = async (eventFile) => {
    const file = eventFile[0];
    if (!file) {
      return;
    }
    const formatType =
      file.type === 'application/pdf'
        ? 'pdf'
        : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ? 'docx'
          : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ? 'xlsx'
            : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
              ? 'xlspptxx'
              : file.type === 'image/png'
                ? 'png'
                : 'jpeg';
    const data = {
      file,
      hash_archivo: `${md5(Date.now())}.${formatType}`,
    };
    setValue('acfi_file', data);
    errors.acfi_file = false;
  };

  const handleRemoveFile = () => {
    setValue('acfi_file', null);
  };

  const handleUploadFile = async (data) => {
    setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', data.acfi_file.file);
    formData.append('hash_archivo', data.acfi_file.hash_archivo);
    formData.append('acfi_name', data.acfi_name);
    formData.append('user_id', item.user_id);
    formData.append('acfi_activities_service_seller_id', item?.ass_id);

    const response = await dispatch(createActivityFile(formData));
    if (response) {
      await dispatch(showMessage({ message: 'Archivo cargardo con exito', variant: 'success' }));
      await dispatch(getActivityFile(item?.ass_id, item?.ass_step));
    }
    cleanData();
    setLoadingFile(false);
  }

  const handleDeleteFile = async (row) => {
    setLoadingImages((prevLoading) => [...prevLoading, row.acfi_id]);

    const response = await dispatch(deleteActivityFile({ user_id: item.user_id, acfi_id: row.acfi_id }))
    if (response) {
      await dispatch(showMessage({ message: 'Archivo eliminado con exito', variant: 'success' }));
      await dispatch(getActivityFile(item?.ass_id, item?.ass_step));
    }
    cleanData();
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
      // style={{
      //   boxShadow: '0px 2px 16px 4px rgba(38, 41, 64, 0.1)',
      //   zIndex: 99,
      // }}
      // className={classes.dialog}
      // minWidth="lg"
      // maxWidth="lg"
      >
        <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
          <p className="font-bold text-xl">
            Subir
          </p>
          <IconButton key="close" aria-label="Close" onClick={() => handleClose()} style={{ position: 'absolute', right: 16 }}>
            <CloseRoundedIcon style={{ color: '#213462' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '600px' }} /* sx={{ width: '600px' }} */>
          {/* ------------ */}
          <div className="mt-4">
            <FormProvider {...methods}>
              <div className="grid xl:grid-cols-1 grid-cols-2 gap-1">
                <div className='xl:col-span-1 col-span-2'>
                  <p color="#262940" className="text-16 mb-2">
                    Adjuntar documento de soporte{' '}
                    <span style={{ color: '#B7BECD' }}>(jpg, jpeg, png, docx, xlsx, pptx, pdf):</span>
                  </p>
                  <Controller
                    name="acfi_file"
                    control={control}
                    render={({ field }) => (
                      <CustomUploadFile
                        {...field}
                        id="acfi_file"
                        handleUploadChange={(e) => handleUploadChange(e)}
                        handleRemoveFile={(e) => handleRemoveFile(e)}
                        accept={{
                          'application/pdf': ['.pdf'],
                          'image/*': ['.jpg', '.jpeg', '.png'],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                            '.doc',
                            '.docx',
                          ],
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                        }}
                        required={!!errors.acfi_file}
                      />
                    )}
                  />
                  <div className=''>
                    <FormControl error={!!errors.acfi_file} className="w-full">
                      <FormHelperText style={{ marginLeft: 0 }}>{errors?.acfi_file?.message}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className='xl:col-span-1 col-span-2'>
                    <Controller
                      name="acfi_name"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          styles={{ mt: 1, mb: 2, width: '100%' }}
                          id="acfi_name"
                          label="Nombre archivo"
                          // value={form.cli_documento}
                          error={!!errors.acfi_name}
                          helperText={errors?.acfi_name?.message}
                          required
                        />
                      )}
                    />
                  </div>
                  <div className='xl:col-span-1 col-span-2 mt-4'>
                    {loadingFile ? (
                      <CircularProgress
                        style={{ color: '#4575D5', marginRight: 4 }}
                        size={24}
                      />
                    ) : (
                      <CustomButton
                        label="Subir archivo"
                        typebutton={2}
                        onClick={handleSubmit(handleUploadFile)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </FormProvider>
            <TableContainer>
              <Table size="small" className="simple mt-4">
                <CustomTableHead
                  order={order.direction}
                  orderBy={order.id}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />
                <TableBody>
                  {items.length > 0 ? items.map((row) => (
                    <TableRow
                      key={row.acfi_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.acfi_name}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ p: '14px 3px' }}
                      >
                        {row.acfi_file && (
                          <Link
                            target="_blank"
                            href={`${API.url_ftp}/${item.user_id}/seguimientos/${row.acfi_file}`}
                            color="#213462"
                            className="text-12 font-bold cursor-pointer"
                          >
                            {/* row.acfi_file */}
                            Ver
                          </Link>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ p: '14px 3px' }}
                      >
                        {loadingImages.includes(row.acfi_id) ? (
                          <CircularProgress
                            style={{ color: '#4575D5', marginRight: 4 }}
                            size={24}
                          />
                        ) :
                          <IconButton
                            onClick={async () => {
                              handleDeleteFile(row)
                            }}
                          >
                            <DeleteSweepRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  )) :
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ padding: 16, fontSize: 12 }}
                        colSpan={5}
                      >
                        No se han encontrado archivos
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* ------------ */}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UploadFileDialog;
