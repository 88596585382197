import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import clsx from "clsx";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { CircularProgress, Card, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CustomTextField from "../../../@components/CustomTextField";
import CustomAutocomplete from "../../../@components/CustomAutocomplete";
import CustomButton from "../../../@components/CustomButton";

import { getDocumentTypes } from "../../../store/documentTypeSlice";
import { createNoAuth } from "../../../store/partnerSlice";
import { showMessage } from "../../../store/messageSlice";
import { getCountries, getStateId, setCleanState } from "../../../store/stateSlice";

import { getCities, setCleanCity } from "../../../store/citySlice";

const useStyles = makeStyles({
    typography: {
        textAlign: "left",
        fontSize: 12,
        marginBottom: 8,
        marginTop: 16,
        fontWeight: 500,
        color: '#99A0AB'
    },
    customText: {
        width: '100%',
        '& .MuiFormHelperText-root': {
            color: 'red',
            marginTop: 4,
            fontWeight: 500,
            textAlign: 'right',
        },
    },
    show: {
        '& .MuiInputBase-root': {
            background: '#fff'
        }
    }
});

const defaultValues = {
    partner_user_id: null,
    partner_name: '',
    partner_lastname: '',
    partner_email: '',
    // partner_hubspot_id: '',
    partner_country_id: null,
    partner_city_id: null,
    partner_phone: '',
    partner_document: '',
    partner_document_type_id: null,
};

const schema = yup.object().shape({
    partner_name: yup.string().required('Nombres es requerido'),
    partner_lastname: yup.string().required('Apellidos es requerido'),
    partner_email: yup.string().email('Email no válido').required('Email es requerido'),
    // partner_hubspot_id: yup.object().required('Estado es requerida'),
    partner_country_id: yup.object().required('País es requerida'),
    // partner_city_id: yup.object().required('Ciudad es requerida'),
    partner_document_type_id: yup.object().required('Tipo documento es requerida'),
    partner_document: yup
        .string()
        .matches(/^\d{7}$|^\d{10}$/, 'Documento debe tener 7 o 10 dígitos')
        .required('Documento es requerido'),
    partner_phone: yup
        .string()
        .notRequired() // No requerido por defecto
        .when('partner_phone', {
            is: (value) => value && value.length > 0, // Si está lleno, aplica validaciones
            then: (schema) =>
                schema
                    .matches(/^[0-9]+$/, 'Celular debe contener solo números')
                    .min(12, 'Celular debe tener al menos 12 dígitos incluyendo el indicativo del país')
                    .max(15, 'Celular debe tener un máximo de 15 dígitos incluyendo el indicativo del país'),
            otherwise: (schema) => schema, // No hace nada si está vacío
        }),

}, [['partner_phone', 'partner_phone']]);

function Index() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const documents = useSelector(({ docType }) => docType.documentsList);
    const cities = useSelector(({ city }) => city.citiesList);
    const countries = useSelector(({ state }) => state.countriesList);
    const states = useSelector(({ state }) => state.statesList);

    const [loading, setLoading] = useState(false);

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { reset, setValue, getValues, control, onChange, formState, handleSubmit } = methods;
    const { errors } = formState;

    useEffect(() => {
        async function init() {
            await dispatch(getDocumentTypes());
            await dispatch(getCountries());
        }
        init();
    }, []);

    /**
     * Function to clean the information
     */
    const cleanData = async () => {
        reset(defaultValues);
        await dispatch(setCleanState())
        await dispatch(setCleanCity())
    };

    const optionDocuments = documents
        ? documents.map((e) => ({
            value: e.document_id,
            label: e.document_type,
        }))
        : [];

    const optionCities = cities
        ? cities.map((e) => ({
            value: e.city_id,
            label: e.fullName,
        }))
        : [];

    const optionCountries = countries
        ? countries.map((e) => ({
            value: e.country_id,
            label: e.country_name
        }))
        : [];

    const optionStates = states
        ? states.map((e) => ({
            value: e.state_id,
            label: e.state_name
        }))
        : [];

    const handleAction = async (data) => {
        setLoading(true);
        const response = await dispatch(createNoAuth({
            ...data,
            partner_document_type_id: data.partner_document_type_id ? data.partner_document_type_id.value : null,
            partner_city_id: data.partner_city_id ? data.partner_city_id.value : null,
            partner_country_id: data.partner_country_id.value,
        }));
        if (response) {
            await dispatch(showMessage({ message: 'Partner creado', variant: 'success' }));
            cleanData();
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    return (
        <Container className=" ">
            <Card className="flex w-full h-full">
                {/* Sección izquierda */}
                <div className="w-1/2 bg-white p-10 flex flex-col justify-center items-center">
                    <div className="mx-auto w-full max-w-100 sm:mx-0 sm:w-100 flex flex-col">
                        <div className="flex flex-col items-center justify-center mt-10" style={{ position: 'relative', zIndex: 2 }}>
                            <img src='/bosanet-new-black.png' width='200' height='60' alt='bosanet-new-white.png' />
                            <p variant="h5" className="mt-2 text-[20px]">Regístrate como partner</p>
                        </div>
                        <FormProvider {...methods}>
                            <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
                                <Controller
                                    name="partner_name"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            styles={{ mt: 1, mb: 2, width: '100%' }}
                                            id="partner_name"
                                            label="Nombres"
                                            error={!!errors.partner_name}
                                            helperText={errors?.partner_name?.message}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="partner_lastname"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            styles={{ mt: 1, mb: 2, width: '100%' }}
                                            id="partner_lastname"
                                            label="Apellidos"
                                            error={!!errors.partner_lastname}
                                            helperText={errors?.partner_lastname?.message}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="partner_country_id"
                                    control={control}
                                    render={({ field: { onChange, value, onBlur, ref } }) => (
                                        <CustomAutocomplete
                                            styles={{ mb: 2 }}
                                            label="País"
                                            id="partner_country_id"
                                            value={value || null}
                                            options={optionCountries}
                                            onChange={async (event, newValue) => {
                                                onChange(event);
                                                setValue("partner_state_id", null, { shouldValidate: true });
                                                setValue("partner_city_id", null, { shouldValidate: true });
                                                await dispatch(getStateId(event?.value));
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.key}>
                                                    {option.label}
                                                </li>
                                            )}
                                            error={!!errors.partner_country_id}
                                            helperText={errors?.partner_country_id?.message}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="partner_state_id"
                                    control={control}
                                    render={({ field: { onChange, value, onBlur, ref } }) => (
                                        <CustomAutocomplete
                                            styles={{ mb: 2 }}
                                            label="Departamento"
                                            id="partner_state_id"
                                            value={value || null}
                                            options={optionStates}
                                            onChange={async (event, newValue) => {
                                                setValue("partner_city_id", null, { shouldValidate: true });
                                                onChange(event);
                                                if (event) {
                                                    await dispatch(getCities(event?.value));
                                                }
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.key}>
                                                    {option.label}
                                                </li>
                                            )}
                                            error={!!errors.partner_state_id}
                                            helperText={errors?.partner_state_id?.message}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="partner_city_id"
                                    control={control}
                                    render={({ field: { onChange, value, onBlur, ref } }) => (
                                        <CustomAutocomplete
                                            styles={{ mb: 2 }}
                                            label="Ciudad"
                                            id="partner_city_id"
                                            value={value || null}
                                            options={optionCities}
                                            onChange={(event, newValue) => {
                                                onChange(event);
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.key}>
                                                    {option.label}
                                                </li>
                                            )}
                                            error={!!errors.partner_city_id}
                                            helperText={errors?.partner_city_id?.message}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="partner_document_type_id"
                                    control={control}
                                    render={({ field: { onChange, value, onBlur, ref } }) => (
                                        <CustomAutocomplete
                                            styles={{ mb: 2 }}
                                            label="Tipo de documento"
                                            id="partner_document_type_id"
                                            value={value || null}
                                            options={optionDocuments}
                                            onChange={(event, newValue) => {
                                                onChange(event);
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.key}>
                                                    {option.label}
                                                </li>
                                            )}
                                            error={!!errors.partner_document_type_id}
                                            helperText={errors?.partner_document_type_id?.message}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="partner_document"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            styles={{ mb: 2, width: '100%' }}
                                            id="partner_document"
                                            label="Documento"
                                            error={!!errors.partner_document}
                                            helperText={errors?.partner_document?.message}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="partner_email"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            styles={{ width: '100%' }}
                                            id="partner_email"
                                            label="Email"
                                            placeholder="your@email.com"
                                            error={!!errors.partner_email}
                                            helperText={errors?.partner_email?.message}
                                            required
                                        />
                                    )}
                                />
                                <Controller
                                    name="partner_phone"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            styles={{ mt: 1, mb: 2, width: '100%' }}
                                            id="partner_phone"
                                            label="Celular"
                                            // value={form.person_phone}
                                            error={!!errors.partner_phone}
                                            helperText={errors?.partner_phone?.message}
                                            required
                                        />
                                    )}
                                />

                            </div>
                            <div className="flex justify-end items-center">
                                <RouterLink style={{ color: '#4575D5' }} className={clsx(classes.customFontBold, "underline cursor-pointer font-semibold mr-4")} to="/sign-in">
                                    Cancelar
                                </RouterLink>
                                {loading ? (
                                    <CircularProgress
                                        style={{ color: '#4575D5', marginRight: 4 }}
                                        size={24}
                                    />
                                ) : (
                                    <CustomButton
                                        label="Registrarte"
                                        typebutton={2}
                                        onClick={handleSubmit(handleAction)}
                                    />
                                )}
                            </div>
                        </FormProvider>
                    </div>
                </div>

                {/* Sección derecha */}
                <div className="w-1/2 bg-[#213462] p-10 text-white flex flex-col justify-center">
                    <Typography variant="h4" className="mb-4">Bienvenido a nuestra comunidad de Bosanet</Typography>
                    <p variant="body1" className="mb-2 mt-4">
                        Al registrarte como partner, tendrás acceso a herramientas innovadoras que te ayudarán a expandir tu negocio, conectar con nuevos clientes y colaborar en un entorno seguro y eficiente.
                    </p>
                    <p variant="body2">
                        Únete a Bosanet, una plataforma diseñada para conectar a empresas y partners del sector.
                    </p>
                </div>
            </Card>
        </Container>
    );

}

export default Index;
