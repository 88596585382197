import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';

export const getTemplateDropdown = () => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.emailtemplates}/dropdown`;
        const result = await axios.get(URL);
        await dispatch(setTemplateDropdown(result.data));
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> getTemplateDropdown *** ${error}`);
    }
};

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.emailtemplates}`;
        const result = await axios.get(URL + queryString);
        await dispatch(setTemplateList(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getAll *** ${error}`);
    }
};

export const create = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.emailtemplates}`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> create *** ${error}`);
    }
};

export const update = (data, id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.emailtemplates}/${id}`;
        await axios.put(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> update *** ${error}`);
    }
};

export const deleteTemplate = (id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.emailtemplates}/${id}`;
        await axios.delete(URL, id);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> delete *** ${error}`);
    }
};

const templateSlice = createSlice({
    name: 'template',
    initialState: {
        dataList: [], // List of templates
        page: 0, // Current page for pagination
        rowsPerPage: 10, // Number of rows per page
        total: 0, // Total number of templates
        templateDropdown: [],
        refreshTemplate: false
    },
    reducers: {
        setTemplateList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setTemplateDropdown: (state, action) => {
            state.templateDropdown = action.payload;
        },
        setRefreshTemplate: (state, action) => {
            state.refreshTemplate = action.payload;
        },
    },
});

export const { setTemplateList, setRowsPerPage, setPage, setTemplateDropdown, setRefreshTemplate } = templateSlice.actions;

export default templateSlice.reducer;