import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,

  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormHelper,
  FormHelperText,
  IconButton,
  Link
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomTableHead from '@components/CustomTableHead';

import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';

import {
  getServiceSupplier,
  createServiceSupplier,
  deleteServiceSupplier,
  updateServiceSupplier
} from '../../../store/supplierSlice';
import {
  getServices
} from '../../../store/serviceSlice';
import { showMessage } from '../../../store/messageSlice';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
  {
    id: 'ss_service_id',
    order: false,
    label: 'Servicio',
  },
  {
    id: 'ss_status',
    order: false,
    label: 'Estado',
  },
  {
    id: 'action',
    order: false,
    label: 'Action',
  },
];

const defaultValues = {
  ss_service_id: null,
  ss_status: null,
};

const schema = yup.object().shape({
  ss_service_id: yup.object().required('Servicio es requerido'),
  ss_status: yup.object().required('Estado es requerida'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function ServicesTab(props) {
  const { mainId } = props;
  const dispatch = useDispatch();
  //-------------------------------------------
  const activity = useSelector(({ supplier }) => supplier.service);
  const services = useSelector(({ service }) => service.servicesList);
  //-------------------------------------------
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingImages, setLoadingImages] = useState([]);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'ss_id',
  });

  const [items, setItems] = useState([]);
  const [changeDataFile, setChangeDataFile] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  //-------------------------------------------
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema), // Resolver para la validación
  });

  const { reset, handleSubmit, control, setValue, formState: formState } = methods;
  const { errors } = formState;
  //-------------------------------------------
  useEffect(() => {
    async function init() {
      await dispatch(getServices());
    }
    init();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (activity) {
        setItems(activity);
      }
    };
    fetch();
  }, [activity]);

  useEffect(() => {
    async function fetch() {
      if (changeDataFile, mainId) {
        await dispatch(getServiceSupplier(mainId));
        setChangeDataFile(false)
      }
    }
    fetch();
  }, [changeDataFile, mainId]);

  useEffect(() => {
    const fetch = async () => {
      if (selectRow) {
        reset({
          ...selectRow,
          ss_status: {
            label: selectRow.ss_status === 0 ? "Inactivo" : "Activo",
            value: selectRow.ss_status,
          },
          ss_service_id: selectRow.ss_service_id === null || selectRow.ss_service_id === '' ? null : {
            label: selectRow.fk_service?.service_name,
            value: selectRow.ss_service_id,
          },
        });
      }
    };
    fetch();
  }, [reset, selectRow]);

  /**
  * Function to clean the information
  */
  const cleanData = async () => {
    setSelectRow(null);
    reset(defaultValues);
  };

  /**
* Handles the table sort request.
* @param {string} property - Property to sort the table by.
*/
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };
  // --------------------------------------------
  const optionTemplate = services
    ? services.map((e) => ({
      value: e.service_id,
      label: e.service_name,
    }))
    : [];
  const statusOptions = [
    { label: 'Inactivo', value: 0 },
    { label: 'Activo', value: 1 }
  ];
  // --------------------------------------------
  const handleActivity = async (data) => {
    setLoadingFile(true);
    const response = await dispatch(createServiceSupplier({
      ...data,
      ss_supplier_id: mainId,
      ss_status: data.ss_status.value,
      ss_service_id: data.ss_service_id.value,
    }));
    if (response) {
      await dispatch(showMessage({ message: 'Actividad creada con exito', variant: 'success' }));
      cleanData();
      setChangeDataFile(true)
    }
    setLoadingFile(false);
  }

  const handleActivityUpdate = async (data) => {
    setLoadingFile(true);
    const response = await dispatch(updateServiceSupplier({
      ...data,
      ss_supplier_id: mainId,
      ss_status: data.ss_status.value,
      ss_service_id: data.ss_service_id.value,
    },
      data.ss_id
    ));
    if (response) {
      await dispatch(showMessage({ message: 'Actividad actualizada con exito', variant: 'success' }));
      cleanData();
      setChangeDataFile(true)
    }
    setLoadingFile(false);
  }

  const handleDelete = async (row) => {
    setLoadingImages((prevLoading) => [...prevLoading, row.ss_id]);
    const response = await dispatch(deleteServiceSupplier({ ss_id: row.ss_id }))
    if (response) {
      await dispatch(showMessage({ message: 'Actividad eliminada con exito', variant: 'success' }));
      await dispatch(getServiceSupplier(mainId));
    }
  }

  return (
    <div sx={{ width: '600px' }}>
      {/* ---------------------- */}
      <div className=''>
        {/* <p className="text-16 font-bold my-2">Archivos</p> */}
        <div className="mt-4">
          <FormProvider {...methods}>
            <div className="grid xl:grid-cols-1 grid-cols-2 gap-1">
              <div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-3">
                <Controller
                  name="ss_service_id"
                  control={control}
                  render={({ field: { onChange, value, onBlur, ref } }) => (
                    <CustomAutocomplete
                      styles={{ mt: 1, mb: 2 }}
                      label="Servicio"
                      id="ss_service_id"
                      value={value || null}
                      options={optionTemplate}
                      onChange={(event, newValue) => {
                        onChange(event);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.key}>
                          {option.label}
                        </li>
                      )}
                      error={!!errors.ss_service_id}
                      helperText={errors?.ss_service_id?.message}
                      required
                    />
                  )}
                />
                <Controller
                  name="ss_status"
                  control={control}
                  render={({ field: { onChange, value, onBlur, ref } }) => (
                    <CustomAutocomplete
                      styles={{ mt: 1, mb: 2, width: '100%' }}
                      label="Estado"
                      id="ss_status"
                      value={value || null}
                      options={statusOptions}
                      onChange={(event, newValue) => {
                        onChange(event);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.key}>
                          {option.label}
                        </li>
                      )}
                      error={!!errors.ss_status}
                      helperText={errors?.ss_status?.message}
                      required
                    />
                  )}
                />
              </div>
              <div className="flex justify-end items-center">
                {loadingFile ? (
                  <CircularProgress
                    style={{ color: '#4575D5', marginRight: 4 }}
                    size={24}
                  />
                ) : (
                  <div className='flex items-center'>
                    <CustomButton
                      label={selectRow === null ? "Guardar" : "Actualizar"}
                      styles={{ mt: 1 }}
                      typebutton={2}
                      onClick={handleSubmit(selectRow === null ? handleActivity : handleActivityUpdate)}
                    />
                    {selectRow && (
                      <p
                        className="mx-4 underline text-base text-gray-600 cursor-pointer"
                        onClick={() => {
                          cleanData();
                        }}
                      >
                        Cancel
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </FormProvider>
          <TableContainer>
            <Table size="small" className="simple mt-4">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items.length > 0 ? items.map((row) => (
                  <TableRow
                    key={row.ss_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.fk_service?.service_name}</TableCell>
                    <TableCell sx={{ p: '15px 3px' }}>
                      <div
                        style={{ color: row.ss_status === 1 ? "#11AE92" : "#EB5757" }}
                      >
                        {row.ss_status === 1 ? "Activo" : "Inactivo"}
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ p: '14px 3px' }}
                    >
                      {loadingImages.includes(row.ss_id) ? (
                        <CircularProgress
                          style={{ color: '#4575D5', marginRight: 4 }}
                          size={24}
                        />
                      ) :
                        <div className='flex'>
                          <IconButton
                            onClick={async () => {
                              setSelectRow(row)
                            }}
                          >
                            <BorderColorRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={async () => {
                              handleDelete(row)
                            }}
                          >
                            <DeleteSweepRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 12 }}
                      colSpan={5}
                    >
                      No se han encontrado archivos
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* ---------------------- */}
    </div>
  );
}

export default ServicesTab;
