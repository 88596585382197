import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, Box, Tabs, Tab, Typography
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import AttachEmailRoundedIcon from '@mui/icons-material/AttachEmailRounded';

import CustomLoading from '@components/CustomLoading';
import CustomTableHead from '@components/CustomTableHead';
import CustomTablePagination from '@components/CustomTablePagination';
import CustomButton from '@components/CustomButton';

import CustomDialog from './CustomDialog';
import CustomFilter from './CustomFilter';
import EmailDialog from './EmailDialog';

import { setRefreshSellerActi, setRowsPerPage, setPage } from '../../../store/sellerActivitySlice';
import { getGroupsDropdown } from "../../../store/sellerActivitiesGroups";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Index() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rows = useSelector(({ sellerActi }) => sellerActi.dataList);
  const page = useSelector(({ sellerActi }) => sellerActi.page);
  const rowsPerPage = useSelector(({ sellerActi }) => sellerActi.rowsPerPage);
  const totalRows = useSelector(({ sellerActi }) => sellerActi.total);
  const groups = useSelector(({ slaGroup }) => slaGroup.groupDropdown);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(0);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'seac_date',
  });
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [itemSelect, setItemSelect] = useState('');
  const [dataGroups, setDataGroups] = useState([]);
  const [valueTab, setValueTab] = useState(0);
  const [groupSelected, setGroupSelect] = useState(0);
  const [emailDialog, setEmailDialog] = useState(false);

  useEffect(() => {
    async function init() {
      setLoading(true);
      await dispatch(getGroupsDropdown());
      setLoading(false);
    }
    init();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (rows && rows.length > 0) {
        setItems(rows);
      } else {
        setItems([]);
      }
    };
    fetch();
  }, [rows]);

  useEffect(() => {
    const fetch = async () => {
      if (groups && groups.length > 0) {
        setDataGroups(groups);
      } else {
        setDataGroups([]);
      }
    };
    fetch();
  }, [groups]);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
   * Handles page change in table pagination.
   * @param {number} newPage - New selected page.
   */
  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };
  /**
   * Handles the change in the number of rows per page in the table pagination.
   * @param {Object} event - React event object.
   */
  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  const toggleDialogCreate = () => {
    setAddDialog(!addDialog);
  };

  const toggleDialogEdit = () => {
    setEditDialog(!editDialog);
  };

  const toggleDialogEmail = () => {
    setEmailDialog(!emailDialog);
  };


  const handleRefresh = async () => {
    dispatch(setRefreshSellerActi(true));
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
    setGroupSelect(newValue)
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('employee_seller_activities')}</p>
        <CustomButton
          label="Nuevo"
          typebutton={2}
          icon={<AddRoundedIcon fontSize="large" />}
          onClick={toggleDialogCreate}
        />
      </div>
      <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        <div className="bg-red flex justify-end pb-4 px-4">
          <div className="flex">
            <CustomTablePagination
              page={page}
              count={totalRows}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
        {loading ? (
          <CustomLoading />
        ) : (
          <>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Todas las actividades" {...a11yProps(0)} />
                  {dataGroups.map((group) => (
                    <Tab key={group.seacg_id} label={group.seacg_name} {...a11yProps(group.seacg_id)} />
                  ))}
                </Tabs>
              </Box>
              <Box sx={{ p: 2 }}>
                <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <CustomFilter
                      order={order}
                      onRequestSort={handleRequestSort}
                      groupSelected={groupSelected}
                    />
                    <TableBody>
                      {items && items.length > 0 ? (
                        items.map((e, index) => {
                          return (
                            <TableRow
                              hover
                              key={e.seac_id}
                              hidden={valueTab !== index}
                            >
                              <TableCell sx={{ p: '15px 3px' }}>{e.seac_id}</TableCell>
                              <TableCell sx={{ p: '15px 3px' }}>{e.fk_seller?.seller_name}</TableCell>
                              <TableCell sx={{ p: '15px 3px' }}>{e.fk_seller_activity_type?.sat_name}</TableCell>
                              <TableCell sx={{ p: '15px 3px' }}>{e.seac_description}</TableCell>
                              <TableCell sx={{ p: '15px 3px' }}>{e.seac_date}</TableCell>
                              <TableCell sx={{ p: '15px 3px' }}>{e.seac_buyer_id ? e.fk_buyer?.buyer_company : "--"}</TableCell>
                              <TableCell
                                align="center"
                                sx={{ p: '14px 3px' }}
                              >
                                <IconButton
                                  onClick={() => {
                                    setItemSelect(e);
                                    setEditDialog(true)
                                  }}
                                >
                                  <BorderColorRoundedIcon
                                    style={{ color: '#213462' }}
                                  />
                                </IconButton>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ p: '14px 3px' }}
                              >
                                {e.seac_copy_email && (
                                  <IconButton
                                    onClick={() => {
                                      setItemSelect(e);
                                      setEmailDialog(true)
                                    }}
                                  // href={`https://www.google.com`}
                                  // target="_blank"
                                  >
                                    <AttachEmailRoundedIcon
                                      style={{ color: '#213462' }}
                                    />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ padding: 16, fontSize: 18 }}
                            colSpan={10}
                          >
                            No se han encontrado datos
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </>
        )}
        <div className="flex justify-end mt-5 px-3">
          <CustomTablePagination
            page={page}
            count={totalRows}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {addDialog && (
        <CustomDialog
          handleClose={toggleDialogCreate}
          handleRefresh={handleRefresh}
          open={addDialog}
          type="create"
        />
      )}
      {editDialog && (
        <CustomDialog
          item={itemSelect}
          open={editDialog}
          handleClose={toggleDialogEdit}
          handleRefresh={handleRefresh}
          type="edit"
        />
      )}
      {emailDialog && (
        <EmailDialog
          item={itemSelect}
          open={emailDialog}
          handleClose={toggleDialogEmail}
          type="create"
        />
      )}
    </div>
  );
}

export default Index;
