import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { format, parseISO, parse, isValid } from 'date-fns';

import { makeStyles } from '@mui/styles';
import {
  TextField,
  MenuItem,
  TableCell,
  TableRow,
  TableSortLabel,
  Tooltip,
  Select,
  TableHead,
  Typography,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';

import { getAll, setRowsPerPage, setPage, setRefreshAnswer } from '../../../store/answerSlice';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomDatePicker from '@components/CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '6px 6px',
      minHeight: 0,
    },
    '& input': {
      fontSize: 12,
      color: '#2e2e2e',
      minHeight: 0,
      padding: '6px 5px',
      background: 'white',
      borderRadius: 4,
    },
  },
  popper: {
    width: '100%',
    '& .MuiPopper-root': { zIndex: 9999 },

    '& .MuiInputBase-input': {
      padding: '6px 16px',
      minHeight: 0,
    },
    '& input': {
      fontSize: 12,
      color: '#2e2e2e',
      minHeight: 0,
      padding: '6px 5px',
      background: 'white',
      borderRadius: 4,
    },
    '& .MuiInputAdornment-root': {
      margin: 0
    },
  },
  customSort: {
    '&.Mui-active': {
      color: '#1B0055'
    },
    '&.MuiTableSortLabel-icon': {
      color: '#1B0055'
    },
    '&.Mui-active > .MuiTableSortLabel-icon': {
      color: '#1B0055'
    },
    '&:hover': {
      color: '#000',
    },
  },
}));

const tableCellStyles = {
  // backgroundColor: 'red',
  color: '#121926',
  fontWeight: 'bold',
  fontSize: 14,
  padding: '10px 5px',
  whiteSpace: 'nowrap',
  '& .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active': {
    color: 'black',
    '& .MuiTableSortLabel-icon': {
      color: '#121926'
    }
  },
  '& .MuiButtonBase-root.MuiTableSortLabel-root:hover': {
    color: '#121926'
  },
  '& .MuiTableCell-root': {
    lineHeight: '1rem',
  },
  '& .MuiTypography-root': {
    color: 'black',
    fontWeight: 'bold',
  },
};

const headCell = [
  {
    id: 'answer_id',
    align: 'center',
    disablePadding: false,
    sort: true,
    label: 'Id',
    width: '3%',
  },
  {
    id: 'answer_question_id',
    align: 'center',
    disablePadding: false,
    sort: false,
    label: 'Pregunta',
    width: '10%',
  },
  {
    id: 'answer_value',
    align: 'center',
    disablePadding: false,
    sort: true,
    label: 'Valor',
    width: '10%',
  },
  {
    id: 'answer_description',
    align: 'center',
    disablePadding: false,
    sort: true,
    label: 'Descripción',
    width: '10%',
  },
  {
    id: 'answer_image',
    align: 'center',
    disablePadding: false,
    sort: true,
    label: 'Imagen',
    width: '5%',
  },
  {
    id: 'answer_complement',
    align: 'center',
    disablePadding: true,
    sort: false,
    label: 'Complemento',
    width: '1%',
  },
  {
    id: 'actions',
    align: 'center',
    disablePadding: false,
    sort: false,
    label: 'Ver',
    width: '2%',
  },
];

const defaultValues = {
  answer_id: '',
  answer_question_id: '',
  answer_value: '',
  answer_description: '',
  answer_image: '',
  answer_complement: '',
};

function CustomFilter(props) {

  const { order, onRequestSort } = props;
  // --------------------------------------------------
  const classes = useStyles();
  const dispatch = useDispatch();

  const page = useSelector(({ answer }) => answer.page);
  const rowsPerPage = useSelector(({ answer }) => answer.rowsPerPage);
  const refresh = useSelector(({ answer }) => answer.refreshAnswer);
  // --------------------------------------------------
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });
  const { watch, setValue, getValues, control } = methods;
  const form = watch();
  // -------------------------------------------------------
  useEffect(() => {
    const fetch = async () => {
      const data = getValues();
      await dispatch(
        getAll(page, rowsPerPage, order.id, order.direction, {
          ...data,

        })
      );
      dispatch(setRefreshAnswer(false));
    };
    fetch();
  }, [dispatch, page, rowsPerPage, order.id, order.direction, refresh]);
  // -------------------------------------------------------
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  // ------------------------------------------------------
  const handledChangeInput = (event) => {
    setValue(event.target.name, event.target.value);
    if (event.target.value === '') {
      setValue(`${event.target.name}`, '');
      handledEnter('random');
    }
  };
  // -------------------------------------------------------
  const handledEnter = async (event) => {
    if (event.key === 'Enter' || event === 'random') {
      const data = getValues();
      await dispatch(setPage(0));
      await dispatch(
        getAll(
          page,
          rowsPerPage,
          order.id,
          order.direction,
          {
            ...data,

          },
        )
      );
    }
  };

  return (
    <TableHead className="h-16 sm:h-16">
      <TableRow>
        {headCell.map((row) => (
          <TableCell
            key={row.id}
            align={row.align ? row.align : 'left'}
            padding={row.disablePadding ? 'none' : 'normal'}
            sortDirection={order.id === row.id ? order : false}
            sx={{ ...tableCellStyles, width: row.width }}
          >
            <div className="">
              {row.sort && (
                <Tooltip
                  title="Ordenar"
                  placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={order.id === row.id}
                    direction={order.direction}
                    onClick={createSortHandler(row.id)}
                    className={clsx(classes.customSort, 'text-11 font-semibold mr-8')}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              )}
              {!row.sort && (
                <Typography className="text-11 font-semibold mr-8">
                  {row.label}
                </Typography>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
      <TableRow style={{ backgroundColor: '#F7F8FC' }} className="">
        {headCell.map((row) => {
          return (
            <TableCell
              // className="px-4 md:px-8 py-0"
              key={row.id}
              align={row.align ? row.align : 'left'}
              padding={row.disablePadding ? 'none' : 'normal'}
              sortDirection={props.order.id === row.id ? props.order.direction : false}
              style={{ width: row.width, padding: '8px 16px 8px 0' }}
            >
              {row.id === 'answer_id' && (
                <Controller
                  name={row.id}
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      className={classes.textField}
                      // style={{ maxWidth: 45 }}
                      id={row.id}
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id === 'answer_question_id' && (
                <Controller
                  name={row.id}
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      className={classes.textField}
                      // style={{ maxWidth: 200 }}
                      id={row.id}
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id === 'answer_value' && (
                <Controller
                  name={row.id}
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      className={classes.textField}
                      // style={{ maxWidth: 200 }}
                      id={row.id}
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id === 'answer_description' && (
                <Controller
                  name={row.id}
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      className={classes.textField}
                      // style={{ maxWidth: 200 }}
                      id={row.id}
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id === 'answer_image' && (
                <Controller
                  name={row.id}
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      className={classes.textField}
                      // style={{ maxWidth: 200 }}
                      id={row.id}
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
              {row.id === 'answer_complement' && (
                <Controller
                  name={row.id}
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      className={classes.textField}
                      // style={{ maxWidth: 200 }}
                      id={row.id}
                      onKeyPress={handledEnter}
                      onChange={handledChangeInput}
                    />
                  )}
                />
              )}
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

export default CustomFilter;
