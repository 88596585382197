import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import AuthGuard from './authGuard';
import { useAuth } from './AuthContext';
import { routes as appRoutes } from './../../configs/routesConfig';
import Login from '../../pages/auth/Login';
import ForgotPassword from '../../pages/auth/ForgotPassword';
import ResetPassword from '../../pages/auth/ResetPassword';
import NotFound from '../../pages/example/NotFound';
import PartnerRegister from '../../pages/auth/PartnerRegister';

const mapModulesToRoutes = (modules_roles, routes) => {
  return modules_roles !== null && modules_roles.length > 0 && routes && routes.length > 0 ?
    modules_roles
      .map(item => {
        const route = routes.find(route => route.path === item.fk_module.module_link);
        return route ? route : null;
      })
      .filter(route => route !== null) // Filtrar los valores nulos
      .sort((a, b) => a.order - b.order)
    : [];
};

function AppRoutes() {
  const { getAccessToken, getModules, /* token */ } = useAuth();
  const token = getAccessToken();
  const modRol = getModules();
  const location = useLocation();

  useEffect(() => {
    if ((location.pathname === '/sign-in' || location.pathname === '/forgot-password' || location.pathname === '/reset-password') && !token) {
      document.body.style.backgroundImage = "url('assets/images/fondo-bosanet2.png')";
      // document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundPosition = 'left';
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.height = '100vh';
      document.body.style.margin = '0';
    } else if (location.pathname === '/partner-register') {
      document.body.style.backgroundImage = "";
      document.body.style.margin = '0';
    } else {
      document.body.style.backgroundImage = 'none';
    }
  }, [location.pathname]);

  const filterRoute = mapModulesToRoutes(modRol, appRoutes);
  const defaultRoute = modRol && modRol.find(t => t.moro_default)?.fk_module.module_link || "/";

  // Rutas accesibles solo para usuarios no autenticados
  const routesForNotAuthenticatedOnly = [
    {
      path: "/sign-in",
      element: <Login />,
    },
    {
      path: "/sign-up",
      element: <div>Sign up Page</div>,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/terms-conditions",
      element: <div>Terms and Conditions Page</div>,
    },
    {
      path: "/help",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <Navigate to="/sign-in" />,
    },
    {
      path: "/",
      element: <Navigate to="/sign-in" />,
    },
    {
      path: "/partner-register",
      element: <PartnerRegister />,
    },
  ];

  // Rutas accesibles solo para usuarios autenticados
  const routesForAuthenticatedOnly = filterRoute.concat({
    path: "*",
    element: <Navigate to={defaultRoute} />,
  }).map((route, index) => ({
    path: route.path,
    element: (
      <AuthGuard>
        {route.element}
      </AuthGuard>
    ),
    key: index,
  }));

  return (
    <Routes>
      {!token && routesForNotAuthenticatedOnly.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      {token && routesForAuthenticatedOnly.map(({ path, element, key }) => (
        <Route key={key} path={path} element={element} />
      ))}
      {/* <Route path="/" element={<Navigate to={token ? defaultRoute?.module_link : "/sign-in"} />} /> */}
    </Routes>
  );
}

export default AppRoutes;
