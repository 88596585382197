import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';

import {
  create,
  update,
} from '../../../store/questionSlice';
import {
  getServicesGroup
} from '../../../store/serviceGroupSlice';
import {
  getCountries
} from '../../../store/stateSlice';
import { showMessage } from '../../../store/messageSlice';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  question_description: '',
  question_order: '',
  question_percentage: '',
  question_service_group_id: null,
  // question_country_id: null,
  question_country_id: [],
};

const schema = yup.object().shape({
  question_description: yup.string().required('Descripción es requerido'),
  question_order: yup
    .number()
    .typeError('Orden debe ser un número')
    .positive('Orden debe ser un número positivo')
    .required('Orden es requerido'),
  question_percentage: yup
    .number()
    .typeError('Porcentaje debe ser un número')
    .positive('Porcentaje debe ser un número positivo')
    .required('Porcentaje es requerido'),
  question_service_group_id: yup.object().required('Grupo es requerida'),
  // question_country_id: yup.object().required('País es requerida'),
  question_country_id: yup.array().min(1, 'Debe seleccionar al menos un país').required('País es requerida'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();

  const countries = useSelector(({ state }) => state.countriesList);
  const groups = useSelector(({ serGroup }) => serGroup.servicesGroupList);

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit } = methods;
  const { errors } = formState;

  const initDialog = useCallback(async () => {
    reset({
      ...item,
      question_service_group_id: {
        label: `${item.fk_service_group?.group_name}`,
        value: item.question_service_group_id,
      },
      // question_country_id: {
      //   label: item.fk_country?.country_name,
      //   value: item.question_country_id,
      // },
      question_country_id: item.questions_countries.map((e) => ({
        label: e.fk_country?.country_name,
        value: e.qc_country_id,
      })),
    });
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getCountries());
      await dispatch(getServicesGroup());
    }
    init();
  }, []);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    reset(defaultValues);
  };

  // --------------------------------------------
  const optionGroups = groups
    ? groups.map((e) => ({
      value: e.group_id,
      label: e.group_name,
    }))
    : [];

  const optionCountries = countries
    ? countries.map((e) => ({
      value: e.country_id,
      label: e.country_name,
    }))
    : [];
  // --------------------------------------------

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create({
        ...data,
        // question_country_id: data.question_country_id.value,
        question_country_id: data.question_country_id.map(t => ({qc_country_id: t.value})),
        question_service_group_id: data.question_service_group_id.value,
      }));
      if (response) {
        await dispatch(showMessage({ message: 'Question creada', variant: 'success' }));
      }
    } else {
      const response = await dispatch(update({
        ...data,
        // question_country_id: data.question_country_id.value,
        question_country_id: data.question_country_id.map(t => ({qc_country_id: t.value})),
        question_service_group_id: data.question_service_group_id.value,
      },
        item.question_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Question actualizada', variant: 'success' }));
      }
    }
    cleanData();
    setLoading(false);
    handleClose();
    handleRefresh();

  };

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Question
        </p>
      </DialogTitle>
      <DialogContent sx={{ width: '500px' }}>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <Controller
              name="question_country_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="País"
                  id="question_country_id"
                  value={value || null}
                  options={optionCountries}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.question_country_id}
                  helperText={errors?.question_country_id?.message}
                  required
                  multiple
                />
              )}
            />
            <Controller
              name="question_service_group_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Grupo de servicio"
                  id="question_service_group_id"
                  value={value || null}
                  options={optionGroups}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.question_service_group_id}
                  helperText={errors?.question_service_group_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="question_percentage"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="question_percentage"
                  label="Porcentaje"
                  // value={form.cli_documento}
                  error={!!errors.question_percentage}
                  helperText={errors?.question_percentage?.message}
                  required
                />
              )}
            />
            <Controller
              name="question_order"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="question_order"
                  label="Orden"
                  // value={form.cli_documento}
                  error={!!errors.question_order}
                  helperText={errors?.question_order?.message}
                  required
                />
              )}
            />
            <Controller
              name="question_description"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  multiline
                  rows={4}
                  maxRows={4}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="question_description"
                  label="Descripción"
                  // value={form.cli_nombre_empresa}
                  error={!!errors.question_description}
                  helperText={errors?.question_description?.message}
                  required
                />
              )}
            />
          </div>
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
