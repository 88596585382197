import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import clsx from "clsx";
import { format, parseISO, differenceInMilliseconds, differenceInDays } from 'date-fns'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FilterRoundedIcon from '@mui/icons-material/FilterRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import CustomTableHead from '@components/CustomTableHead';
import CustomActionDialog from '@components/CustomActionDialog';
import CustomClickAwayListener from '@components/CustomClickAwayListener';

import DownloadFileDialog from './DownloadFileDialog';
import UploadFileDialog from './UploadFileDialog';

import { showMessage } from '../../../store/messageSlice';
import { getActivityById, setCleanTrack } from '../../../store/sellerServicesSlice';

const useStyles = makeStyles({
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 800,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
});

const headCells = [
  {
    id: 'ass_service_id',
    order: false,
    label: 'Dias del servicio',
  },
  {
    id: 'ass_status',
    order: false,
    label: 'Porcentaje'
  },
  {
    id: 'ass_start_date',
    order: false,
    label: 'Fecha ejecución'
  },
  {
    id: 'sese_estimated_date',
    order: false,
    label: 'Fecha estimada'
  },
  {
    id: 'ass_end_date',
    order: false,
    label: 'Fecha finalizado',
  },
];

const headCellsTwo = [
  {
    id: 'ass_id',
    order: false,
    label: 'Estado'
  },
  {
    id: 'ass_status',
    order: false,
    label: 'Actividad'
  },
  {
    id: 'ass_execution_date',
    order: false,
    label: 'Fecha ejecución',
  },
  {
    id: 'ass_description',
    order: false,
    label: 'Notas',
  },
  {
    id: 'action',
    order: false,
    label: 'Archivos'
  },
];

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function ServicesTracking(props) {
  const { item } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const activity = useSelector(({ sellerServ }) => sellerServ.trackById);
  const [dataAct, setDataAct] = useState([]);
  const [items, setItems] = useState({});
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'ass_id',
  });
  const [openDownloadFile, setOpenDownloadFile] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [openUploadFile, setOpenUploadFile] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!isEmpty(item)) {

        if (item.sese_start_date !== null && item.sese_estimated_date !== null) {
          let percentage;
          if (item.sese_end_date !== null) {
            percentage = 100;
          } else {
            const fecha_inicial = parseISO(`${item.sese_start_date}`);
            const fecha_final = parseISO(`${item.sese_estimated_date}`);
            // Usa la fecha actual sin formatearla
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Para eliminar las horas
            // Cálculo del tiempo total entre fecha inicial y final
            const totalTime = differenceInMilliseconds(fecha_final, fecha_inicial);
            // Cálculo del tiempo transcurrido desde la fecha inicial hasta hoy
            const elapsedTime = differenceInMilliseconds(today, fecha_inicial);

            // Calcula el porcentaje
            const progress = (elapsedTime / totalTime) * 100;
            // console.log('progress:', progress);

            // Cálculo del tiempo total entre fecha inicial y final en días
            // const totalTime2 = differenceInDays(fecha_final, fecha_inicial);
            // console.log('Total de días:', totalTime2);

            // Cálculo del tiempo transcurrido desde la fecha inicial hasta hoy en días
            // const elapsedTime2 = differenceInDays(today, fecha_inicial);
            // console.log('Días transcurridos:', elapsedTime2);

            // Ajuste del porcentaje para que esté entre 0% y 100%

            if (today < fecha_inicial) {
              percentage = 0;
            } else if (today > fecha_final) {
              percentage = 100;
            } else {
              percentage = progress;
            }
          }
          setItems({ ...item, percentage });
        } else {
          setItems({ ...item, percentage: -1 });
        }
      }
    };
    fetch();
  }, [item]);

  useEffect(() => {
    const fetch = async () => {
      if (activity && activity.length > 0) {
        setDataAct(activity);
      } else {
        setDataAct([]);
      }
    };
    fetch();
  }, [activity]);

  useEffect(() => {
    async function init() {
      await dispatch(getActivityById(item.sese_id));
    }
    init();

    return async () => {
      await dispatch(setCleanTrack())
    }
  }, []);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  const toggleDialogClose = () => {
    setSelectRow(null);
    setOpenDownloadFile(!openDownloadFile);
  };

  const toggleDialogUploadClose = () => {
    setSelectRow(null);
    setOpenUploadFile(!openUploadFile);
  };

  const handleRefreshInfo = async () => {
    await dispatch(getActivityById(item.sese_id));
  };

  return (
    <>
      {/* ------------ */}
      <div className="mt-4">
        <div className='mb-5'>
          <p className="text-16 font-bold my-2 flex">
            <BusinessCenterRoundedIcon style={{ color: '#213462', marginRight: 3 }} />
            Información
          </p>
          <div className="mb-0">
            <p className="text-14">Servicio: <span>({item.sese_service_id}) - {item.fk_service?.service_name}</span></p>
          </div>
        </div>
        {/* </div> */}
        <div className="mb-0">
          <div className="table-responsive mb-10">
            <Table className="simple mt-4">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {!isEmpty(items) ? (
                  <TableRow>
                    <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.fk_service?.service_sla_bosanet} dias</TableCell>
                    <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.percentage >= 0 ? `${items.percentage.toFixed(2)}%` : ""}</TableCell>
                    <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.sese_start_date ? format(parseISO(`${items.sese_start_date}`), "dd-MM-yyyy") : null}{' '}</TableCell>
                    <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.sese_estimated_date ? format(parseISO(`${items.sese_estimated_date}`), "dd-MM-yyyy") : null}{' '}</TableCell>
                    <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.sese_end_date ? format(parseISO(`${items.sese_end_date}`), "dd-MM-yyyy") : null}{' '}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 12 }}
                      colSpan={4}
                    >
                      No se han encontrado datos
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      {/* ------------ */}
      <div className="mt-4">
        <p className="text-16 font-bold my-2 flex">
          <LibraryBooksRoundedIcon style={{ color: '#213462', marginRight: 3 }} />
          Actividades
        </p>
        <TableContainer>
          <Table size="small" className="simple mt-4">
            <CustomTableHead
              order={order.direction}
              orderBy={order.id}
              onRequestSort={handleRequestSort}
              headCells={headCellsTwo}
            />
            <TableBody>
              {dataAct.length > 0 ?
                dataAct.map((row) => (
                  <TableRow
                    key={row.ass_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ p: '15px 10px 15px 3px' }}>
                      <p
                        className={clsx(
                          'inline-flex justify-center items-center px-14 py-1 rounded-full tracking-wide uppercase whitespace-nowrap',
                          row.ass_status === 1 &&
                          'bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50',
                          (row.ass_status === 2) &&
                          'bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-50',
                          (row.ass_status === 3 || row.ass_status === 4) &&
                          'bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50',
                        )}
                        style={{ fontSize: 10, maxWidth: 100 }}
                      >
                        {row.ass_status === 4 ? 'Validando' : row.ass_status === 3 ? 'Ejecutado' : row.ass_status === 2 ? 'Progreso' : 'Pendiente'}
                      </p>
                    </TableCell>
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.fk_services_activities?.seac_name}</TableCell>
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.ass_execution_date}</TableCell>
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.ass_description}</TableCell>
                    <TableCell
                      align="left"
                      sx={{ p: '14px 3px' }}
                    >
                      {/* CARGAR INFORMACIÓN REQUERIDA */}
                      {row.ass_step === 1 && (
                        <div className='flex whitespace-pre'>
                          <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => {
                            setOpenDownloadFile(true);
                            setSelectRow({ ...row, user_id: item.fk_seller?.persons?.person_user_id })
                          }}>Guía</Link>
                          {/* {row.ass_status === 3 && (*/}
                          <>
                            &nbsp;|&nbsp;
                            <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => {
                              setOpenUploadFile(true);
                              setSelectRow({ ...row, user_id: item.fk_seller?.persons?.person_user_id })
                            }}>Archivos</Link>
                          </>
                        </div>
                      )}

                      {/* EJECUCIÓN DEL SERVICIO */}
                      {row.ass_step === 3 && (
                        <>
                          <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => {
                            setOpenUploadFile(true);
                            setSelectRow({ ...row, user_id: item.fk_seller?.persons?.person_user_id })
                          }}>Informe final</Link>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )) :
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ padding: 16, fontSize: 12 }}
                    colSpan={5}
                  >
                    No se han encontrado datos
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* ------------ */}
      {openDownloadFile && (
        <DownloadFileDialog
          item={selectRow}
          open={openDownloadFile}
          handleClose={toggleDialogClose}
          type=""
        />
      )}
      {openUploadFile && (
        <UploadFileDialog
          item={selectRow}
          open={openUploadFile}
          handleClose={toggleDialogUploadClose}
          handleRefresh={handleRefreshInfo}
          type=""
        />
      )}
      {/* ------------ */}
    </>
  );
}

export default ServicesTracking;
