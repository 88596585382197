import { Navigate } from "react-router-dom";

import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TroubleshootRoundedIcon from '@mui/icons-material/TroubleshootRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import RoomServiceRoundedIcon from '@mui/icons-material/RoomServiceRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import LocalPlayRoundedIcon from '@mui/icons-material/LocalPlayRounded';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BadgeIcon from '@mui/icons-material/Badge';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import RoomPreferencesRoundedIcon from '@mui/icons-material/RoomPreferencesRounded';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';

import Dashboard from '../pages/example/Dashboard';

import Answers from '../pages/admin/Answers';
import Cities from '../pages/admin/Cities';
import Sellers from '../pages/admin/Sellers';
import Persons from '../pages/admin/Persons';
import Roles from '../pages/admin/Roles';
import DocumentTypes from '../pages/admin/DocumentTypes';
import States from '../pages/admin/States';
import ServicesGroup from '../pages/admin/ServicesGroup';
import ServicesList from '../pages/admin/ServicesList';
import Questions from '../pages/admin/Questions';
import Buyers from '../pages/admin/Buyers';
import Employees from '../pages/admin/Employees';
import Suppliers from '../pages/admin/Suppliers';
import SellerServices from '../pages/admin/SellerServices';

import SupplierSellerService from '../pages/supplier/SellerService';
import SupplierProfile from '../pages/supplier/Profile';

import EmployeeProfile from '../pages/employee/Profile';
import EmployeeSellerActivities from '../pages/employee/SellerActivities';
import EmployeeSellerService from '../pages/employee/SellerService';

import Diagnostics from '../pages/seller/Diagnostics';
import Evaluations from '../pages/seller/Evaluations';
import Profile from '../pages/seller/Profile';
import Purchases from '../pages/seller/Purchases';
import Products from '../pages/seller/Products';
import Activities from '../pages/seller/Activities';

import PartnersProfile from '../pages/partner/Profile';
import EmailTemplates from '../pages/admin/EmailTemplates';
import ActivitiesTypes from '../pages/admin/ActivitiesType';
import Partners from '../pages/admin/Partners';
import PartnersReferrals from '../pages/partner/Referrals';
import TrackingRangeMe from '../pages/admin/TrackingRangeMe';

export const routes = [
  // *** ROL ADMIN ***
  {
    path: '/',
    // title: 'Dashboard',
    element: <Dashboard />,
    icon: <HomeRoundedIcon />,
    translate: 'dashboard',
  },
  {
    path: '/sellers',
    // title: 'Sellers',
    element: <Sellers />,
    icon: <GroupsRoundedIcon />,
    translate: 'sellers',
  },
  {
    path: '/roles',
    // title: 'Roles',
    element: <Roles />,
    icon: <KeyRoundedIcon />,
    translate: 'roles',
  },
  {
    path: '/document-types',
    // title: 'Document Types',
    element: <DocumentTypes />,
    icon: <TextSnippetRoundedIcon />,
    translate: 'document_types',
  },
  {
    path: '/states',
    // title: 'States',
    element: <States />,
    icon: <PlaceRoundedIcon />,
    translate: 'states',
  },
  {
    path: '/cities',
    // title: 'Cities',
    element: <Cities />,
    icon: <NearMeRoundedIcon />,
    translate: 'cities',
  },
  {
    path: '/services-group',
    // title: 'Services Group',
    element: <ServicesGroup />,
    icon: <HomeRepairServiceRoundedIcon />,
    translate: 'services_group',
  },
  {
    path: '/services',
    // title: 'Services',
    element: <ServicesList />,
    icon: <BuildRoundedIcon />,
    translate: 'services',
  },
  {
    path: '/questions',
    // title: 'Questions',
    element: <Questions />,
    icon: <QuestionAnswerRoundedIcon />,
    translate: 'questions',
  },
  {
    path: '/answers',
    // title: 'Answers',
    element: <Answers />,
    icon: <ReceiptRoundedIcon />,
    translate: 'answers',
  },
  {
    path: '/persons',
    // title: 'Persons',
    element: <Persons />,
    icon: <GroupRoundedIcon />,
    translate: 'persons',
  },
  {
    path: '/employees',
    // title: 'Employees',
    element: <Employees />,
    icon: <BadgeIcon />,
    translate: 'employees',
  },
  {
    path: '/suppliers',
    // title: 'Suppliers',
    element: <Suppliers />,
    icon: <EngineeringIcon />,
    translate: 'suppliers',
  },
  {
    path: '/buyers',
    // title: 'Buyers',
    element: <Buyers />,
    icon: <AssignmentIndIcon />,
    translate: 'buyers',
  },
  {
    path: '/seller-services',
    // title: 'Seller Services',
    element: <SellerServices />,
    icon: <SupportAgentRoundedIcon />,
    translate: 'seller_services',
  },
  {
    path: '/email-templates',
    // title: 'Seller Services',
    element: <EmailTemplates />,
    icon: <ForwardToInboxRoundedIcon />,
    translate: 'email_templates',
  },
  {
    path: '/activities-type',
    // title: 'Seller Services',
    element: <ActivitiesTypes />,
    icon: <PendingActionsRoundedIcon />,
    translate: 'activities_types',
  },
  {
    path: '/partners-list',
    // title: 'Seller Services',
    element: <Partners />,
    icon: <HandshakeRoundedIcon />,
    translate: 'partners_list',
  },
  {
    path: '/tracking-rangeme',
    //title: '',
    element: <TrackingRangeMe/>,
    icon: <CloudDownloadRoundedIcon/>,
    translate: 'tracking_rangeme',
  },


  // *** ROL SELLERS ***
  {
    path: '/seller-diagnostics',
    // title: 'Diagnostics',
    element: <Diagnostics />,
    icon: <TroubleshootRoundedIcon />,
    translate: 'diagnostics',
  },
  {
    path: '/seller-evaluations',
    // title: 'Evaluations',
    element: <Evaluations />,
    icon: <InventoryRoundedIcon />,
    translate: 'evaluations',
  },
  {
    path: '/profile-seller', // profile-seller
    // title: 'Profile',
    element: <Profile />,
    icon: <ManageAccountsRoundedIcon />,
    translate: 'profile',
  },
  {
    path: '/seller-purchases',
    // title: 'Purchases',
    element: <Purchases />,
    icon: <RoomServiceRoundedIcon />,
    translate: 'my_purchases',
  },
  {
    path: '/seller-products',
    // title: 'Products',
    element: <Products />,
    icon: <LocalGroceryStoreRoundedIcon />,
    translate: 'my_products',
  },
  {
    path: '/seller-activities',
    // title: 'Activities',
    element: <Activities />,
    icon: <LocalPlayRoundedIcon />,
    translate: 'my_activities',
  },


  // *** ROL SUPPLIERS ***
  {
    path: '/profile-supplier',
    // title: 'Profile',
    element: <SupplierProfile />,
    icon: <ManageAccountsRoundedIcon />,
    translate: 'profile',
  },
  {
    path: '/supplier-seller-service',
    // title: 'Supplier Seller Service',
    element: <SupplierSellerService />,
    icon: <SupportAgentRoundedIcon />,
    translate: 'supplier_sellers_service',
  },


  // *** ROL EMPLOYEES ***
  {
    path: '/profile-employee',
    // title: 'Profile',
    element: <EmployeeProfile />,
    icon: <ManageAccountsRoundedIcon />,
    translate: 'profile',
  },
  {
    path: '/employee-seller-activities',
    // title: 'Employee Seller Activities',
    element: <EmployeeSellerActivities />,
    icon: <LocalPlayRoundedIcon />,
    translate: 'employee_seller_activities',
  },
  {
    path: '/employee-seller-service',
    // title: 'Supplier Seller Service',
    element: <EmployeeSellerService />,
    icon: <SupportAgentRoundedIcon />,
    translate: 'employee_sellers_service',
  },


  // *** ROL PARTNER ***
  {
    path: '/profile-partner', // profile-partner
    title: 'Profile',
    element: <PartnersProfile />,
    icon: <ManageAccountsRoundedIcon />,
    translate: 'profile',
  },
  {
    path: '/partner-referrals',
    title: 'Referrals',
    element: <PartnersReferrals />,
    icon: <RoomPreferencesRoundedIcon />,
    translate: 'partners_referrals',
  },
];
