import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomCheckbox from '@components/CustomCheckbox';

import {
  create,
  update,
} from '../../../store/sellerSlice';
import {
  getDocumentTypes,
} from '../../../store/documentTypeSlice';
import {
  getCities,
  setCleanCity
} from '../../../store/citySlice';
import {
  getCountries,
  getStateId,
  setCleanState
} from '../../../store/stateSlice';
import { showMessage } from '../../../store/messageSlice';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  seller_name: '',
  seller_document: '',
  seller_document_type_id: null,
  seller_address: '',
  seller_city_id: null,
  seller_link_video: null,
  seller_url: null,
  seller_enable_diagnostic: false,

  seller_country: null,
  seller_state_id: null,
  seller_hubspot_company: '',

  seller_type: null,
  seller_state: null,
};

const schema = yup.object().shape({
  seller_name: yup.string().required('Empresa es requerido'),
  seller_country: yup.object().required('País es requerida'),
  seller_state: yup.object().required('Estado es requerida'),
  seller_type: yup.object().required('Tipo es requerida'),
  // seller_enable_diagnostic: yup.boolean()
  //   .oneOf([true], 'Habilitar exportCkeck es requerida.')  // Puedes personalizar el mensaje
  //   .required('Este campo es obligatorio'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();

  const documents = useSelector(({ docType }) => docType.documentsList);
  const cities = useSelector(({ city }) => city.citiesList);
  const countries = useSelector(({ state }) => state.countriesList);
  const states = useSelector(({ state }) => state.statesList);

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, setValue, getValues, control, onChange, formState, handleSubmit } = methods;
  const { errors } = formState;

  const initDialog = useCallback(async () => {
    reset({
      ...item,
      seller_enable_diagnostic: item.seller_enable_diagnostic === 1 ? true : false,
      seller_city_id: item.seller_city_id === null || item.seller_city_id === '' ? null : {
        label: `${item.fk_city?.city_name}`,
        value: item.seller_city_id,
      },
      seller_document_type_id: item.seller_document_type_id === null || item.seller_document_type_id === '' ? null : {
        label: item.fk_document_type?.document_type,
        value: item.seller_document_type_id,
      },
      seller_state_id: item.seller_city_id === null || item.seller_city_id === '' ? null : {
        label: item.fk_city?.fk_state?.state_name,
        value: item.fk_city?.fk_state?.state_id,
      },
      seller_country: item.seller_country === null || item.seller_country === '' ? null : {
        label: item.fk_country?.country_name,
        value: item.fk_country?.country_id,
      },
      seller_state: {
        label: item.seller_state === 1 ? "Activo" : item.seller_state === 2 ? "Pendiente" : item.seller_state === 3 ? "Rechazado" : item.seller_state === 4 ? "Eliminado" : "Inactivo",
        value: item.seller_state,
      },
      seller_type: {
        label: item.seller_type === 1 ? "Seller" : "Referido",
        value: item.seller_type,
      },
    });
    if (item.seller_country !== null) {
      await dispatch(getStateId(item.fk_country?.country_id)); // country_id
    }
    if (item.seller_city_id !== null) {
      await dispatch(getCities(item.fk_city?.fk_state?.state_id)); // state_id
    }
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getDocumentTypes());
      await dispatch(getCountries());
    }
    init();
  }, []);

  /**
  * Function to clean the information
  */
  const cleanData = async () => {
    reset(defaultValues);
    await dispatch(setCleanState())
    await dispatch(setCleanCity())
  };

  // --------------------------------------------
  const typeOptions = [
    { label: 'Seller', value: 1 },
    { label: 'Referido', value: 2 }
  ];

  const statusOptions = [
    { label: 'Inactivo', value: 0 },
    { label: 'Activo', value: 1 },
    { label: 'Pendiente', value: 2 },
    { label: 'Rechazado', value: 3 },
    { label: 'Eliminado', value: 4 }
  ];

  const optionDocuments = documents
    ? documents.map((e) => ({
      value: e.document_id,
      label: e.document_type,
    }))
    : [];

  const optionCities = cities
    ? cities.map((e) => ({
      value: e.city_id,
      label: e.fullName,
    }))
    : [];

  const optionCountries = countries
    ? countries.map((e) => ({
      value: e.country_id,
      label: e.country_name
    }))
    : [];

  const optionStates = states
    ? states.map((e) => ({
      value: e.state_id,
      label: e.state_name
    }))
    : [];
  // --------------------------------------------

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create({
        ...data,
        seller_document_type_id: data.seller_document_type_id ? data.seller_document_type_id.value : null,
        seller_city_id: data.seller_city_id ? data.seller_city_id.value : null,
        seller_country: data.seller_country.value,
        seller_enable_diagnostic: data.seller_enable_diagnostic ? 1 : 0,
        seller_state: data.seller_state.value,
        seller_type: data.seller_type.value,
      }));
      if (response) {
        await dispatch(showMessage({ message: 'Seller creada', variant: 'success' }));
      }
    } else {
      const response = await dispatch(update({
        ...data,
        seller_document_type_id: data.seller_document_type_id ? data.seller_document_type_id.value : null,
        seller_city_id: data.seller_city_id ? data.seller_city_id.value : null,
        seller_country: data.seller_country.value,
        seller_enable_diagnostic: data.seller_enable_diagnostic ? 1 : 0,
        seller_state: data.seller_state.value,
        seller_type: data.seller_type.value,
      },
        item.seller_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Seller actualizada', variant: 'success' }));
      }
    }
    cleanData();
    setLoading(false);
    handleClose();
    handleRefresh();

  };

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Seller
        </p>
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <Controller
              name="seller_hubspot_company"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="seller_hubspot_company"
                  label="Hubspot company id"
                  // value={form.person_phone}
                  error={!!errors.seller_hubspot_company}
                  helperText={errors?.seller_hubspot_company?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_name"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="seller_name"
                  label="Empresa"
                  // value={form.cli_nombre_empresa}
                  error={!!errors.seller_name}
                  helperText={errors?.seller_name?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_country"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="País"
                  id="seller_country"
                  value={value || null}
                  options={optionCountries}
                  onChange={async (event, newValue) => {
                    onChange(event);
                    setValue("seller_state_id", null, { shouldValidate: true });
                    setValue("seller_city_id", null, { shouldValidate: true });
                    await dispatch(getStateId(event?.value));
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.seller_country}
                  helperText={errors?.seller_country?.message}
                  required
                  disabled={type === 'create' ? false : true}
                />
              )}
            />
            <Controller
              name="seller_state_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Departamento"
                  id="seller_state_id"
                  value={value || null}
                  options={optionStates}
                  onChange={async (event, newValue) => {
                    setValue("seller_city_id", null, { shouldValidate: true });
                    onChange(event);
                    if (event) {
                      await dispatch(getCities(event?.value));
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.seller_state_id}
                  helperText={errors?.seller_state_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_city_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 1 }}
                  label="Ciudad"
                  id="seller_city_id"
                  value={value || null}
                  options={optionCities}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.seller_city_id}
                  helperText={errors?.seller_city_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_enable_diagnostic"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomCheckbox
                  styles={{ mt: 0, mb: 2, width: '100%' }}
                  id="seller_enable_diagnostic"
                  label="Habilitar Export Check"
                  onChange={(ev) => {
                    onChange(ev.target.checked);
                  }}
                  checked={value}
                  onBlur={onBlur}
                  inputRef={ref}
                  // rounded="true"
                  error={!!errors.seller_enable_diagnostic}
                  helperText={errors?.seller_enable_diagnostic?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_document_type_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Tipo de documento"
                  id="seller_document_type_id"
                  value={value || null}
                  options={optionDocuments}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.seller_document_type_id}
                  helperText={errors?.seller_document_type_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_document"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="seller_document"
                  label="Documento"
                  // value={form.cli_documento}
                  error={!!errors.seller_document}
                  helperText={errors?.seller_document?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_address"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="seller_address"
                  label="Dirección"
                  // value={form.seller_address}
                  // onChange={onChangeText('seller_address')}
                  error={!!errors.seller_address}
                  helperText={errors?.seller_address?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_link_video"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="seller_link_video"
                  label="Link de video"
                  // value={form.seller_link_video}
                  // onChange={onChangeText('seller_link_video')}
                  error={!!errors.seller_link_video}
                  helperText={errors?.seller_link_video?.message}
                // required
                />
              )}
            />
            <Controller
              name="seller_url"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="seller_url"
                  label="Url pagina"
                  // value={form.cli_celular}
                  error={!!errors.seller_url}
                  helperText={errors?.seller_url?.message}
                // required
                />
              )}
            />
            <Controller
              name="seller_type"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Tipo"
                  id="seller_type"
                  value={value || null}
                  options={typeOptions}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.seller_type}
                  helperText={errors?.seller_type?.message}
                  required
                />
              )}
            />
            <Controller
              name="seller_state"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Estado"
                  id="seller_state"
                  value={value || null}
                  options={statusOptions}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.seller_state}
                  helperText={errors?.seller_state?.message}
                  required
                />
              )}
            />
          </div>
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
