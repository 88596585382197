import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, Box, Tabs, Tab, Typography
} from '@mui/material';

import PlayCircleFilledWhiteRoundedIcon from '@mui/icons-material/PlayCircleFilledWhiteRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';

import CustomLoading from '@components/CustomLoading';
import CustomButton from "@components/CustomButton";
import CustomTablePagination from '@components/CustomTablePagination';
import CustomTableHead from '@components/CustomTableHead';

import { startTracking, stopTracking } from "../../../store/trackingRangeMeSlice";

const headCells = [
    {
        id: 'trm_company',
        order: false,
        label: 'Empresa',
    },
    {
        id: 'trm_location',
        order: false,
        label: 'Ubicación',
    },
    {
        id: 'trm_stores',
        order: false,
        label: 'Tiendas',
    },
    {
        id: 'trm_method',
        order: false,
        label: 'Método',
    },
    {
        id: 'trm_through_product',
        order: false,
        label: 'Producto Buscado',
    },
    {
        id: 'trm_seller_name',
        order: false,
        label: 'Seller',
    },
    {
        id: 'trm_product_name',
        order: false,
        label: 'Producto Visto',
    },
    {
        id: 'trm_time_ago',
        order: false,
        label: 'Tiempo',
    },
    {
        id: 'trm_downloaded_at',
        order: false,
        label: 'Fecha Descarga',
    },
];

function Index() {

    const dispatch = useDispatch();

    const isTracking = useSelector((state) => state.RangeMe.isTracking);
    const rows = useSelector((state) => state.RangeMe.dataList);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState({
        direction: 'desc',
        id: 'ss_id',
    });

    useEffect(() => {
        if (isTracking) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isTracking]);

    useEffect(() => {
        const fetch = async () => {
            if (rows && rows.length > 0) {
                setItems(rows);
            }
        };
        fetch();
    }, [rows]);

    const handleStart = () => {
        dispatch(startTracking());
    };

    const handleStop = () => {
        dispatch(stopTracking());
    };

    /**
* Handles the table sort request.
* @param {string} property - Property to sort the table by.
*/
    const handleRequestSort = (event, property) => {
        const isAsc = order.id === property && order.direction === 'asc';
        setOrder({
            direction: isAsc ? 'desc' : 'asc',
            id: property,
        });
    };

    return (
        <div>
            <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
                <p className="text-xl font-semibold">{'Tracking Range.Me'}</p>
                <div className="flex items-center gap-2">
                    {loading ? (
                        <CustomLoading />
                    ) :
                        (<CustomButton
                            label="Iniciar"
                            typebutton={2}
                            icon={<PlayCircleFilledWhiteRoundedIcon fontSize="large" />}
                            onClick={handleStart}
                        />)}
                    <CustomButton
                        label="Detener"
                        typebutton={2}
                        icon={<PauseCircleFilledRoundedIcon fontSize="large" />}
                        onClick={handleStop}
                    />
                </div>
            </div>
            <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">

                <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
                    {/* <div className="bg-red flex justify-end pb-4 px-4">
                        <div className="flex">
                            <CustomTablePagination
                                page={page}
                                count={totalRows}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div> */}
                    {loading ? (
                        <CustomLoading />
                    ) : (
                        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <CustomTableHead
                                    order={order.direction}
                                    orderBy={order.id}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                />
                                <TableBody>
                                    {items && items.length > 0 ? (
                                        items.map((e) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    key={e.trm_id}
                                                >
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_company}</TableCell>
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_location}</TableCell>
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_stores}</TableCell>
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_method}</TableCell>
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_throughProduct}</TableCell>
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_seller_name} {e.trm_seller_id ? `(${e.trm_seller_id})` : null}</TableCell>
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_productName}</TableCell>
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_timeAgo}</TableCell>
                                                    <TableCell sx={{ p: '15px 3px' }}>{e.trm_downloadedAt}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                style={{ padding: 16, fontSize: 18 }}
                                                colSpan={10}
                                            >
                                                No se han encontrado datos
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {/* <div className="flex justify-end mt-5 px-3">
                        <CustomTablePagination
                            page={page}
                            count={totalRows}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );

}

export default Index;