import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar
} from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import { showMessage } from '../../../store/messageSlice';
import API from '../../../configs/urlsConfig';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item } = props;
  const dispatch = useDispatch();
  const authUser = useSelector(({ user }) => user.user);
  
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      if (!isEmpty(item) && item.seller_activities_file.length > 0) {
        setItems(item.seller_activities_file);
      }
    };
    fetch();
  }, [item]);

  return (
    <Dialog open={open} TransitionComponent={Transition} minWidth="md"
      maxWidth="md">
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          Detalle actividad
        </p>
        <IconButton key="close" aria-label="Close" onClick={() => handleClose()} style={{ position: 'absolute', right: 16 }}>
          <CloseRoundedIcon style={{ color: '#213462' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent /*sx={{ width: '400px' }}*/>
        <ImageList
          sx={{
            width: 500,
            height: 450,
            // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
            transform: 'translateZ(0)',
          }}
          rowHeight={200}
          gap={1}
        >
          {items.map((item) => {
            // const cols = item.featured ? 2 : 1;
            // const rows = item.featured ? 2 : 1;
            const cols = 1;
            const rows = 2;
            return (
              <ImageListItem key={item.saf_id} cols={cols} rows={rows}>
                <img
                  {...srcset(`${API.url_ftp}/${authUser?.user_id}/actividades/${item.saf_file}`, 250, 200, rows, cols)}
                  alt={item.saf_name}
                  loading="lazy"
                />
                <ImageListItemBar
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  }}
                  title={item.saf_name}
                  position="top"
                  actionIcon={
                    <IconButton
                      sx={{ color: 'white' }}
                      aria-label={`star ${item.saf_name}`}
                    >
                      {/* <VisibilityRoundedIcon /> */}
                    </IconButton>
                  }
                // actionPosition="left"
                />
              </ImageListItem>
            );
          })}
        </ImageList>
        {/* ------------ */}
        <div className="flex justify-end my-4 items-center">
          <p
            className="mx-4 underline text-base text-gray-600 cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            Cerrar
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
