import React, { useState, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css'; // Importa el estilo de Quill
import clsx from 'clsx';
import { FormHelperText } from '@mui/material';

import ReactQuill, { Quill } from 'react-quill';
import imageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', imageResize);
window.Quill = Quill

function WYSIWYGEditor(props, ref) {

    const { onChange, className = '', value, error, helperText, placeholder } = props;
    const [editorHtml, setEditorHtml] = useState('');
    const quillRef = useRef(null); // Referencia al editor Quill

    // Maneja el cambio en el editor
    // const handleEditorChange = (value) => {
    //     setEditorHtml(value.replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, ''));
    //     return onChange(value);
    // };

    // useEffect(() => {
    //     if (value) {
    //         setEditorHtml(value);
    //     }
    // }, [value]);

    return (
        <div
            className={clsx('w-full  rounded-4 border-1', className)}
        >
            <ReactQuill
                // value={editorHtml}
                // onChange={handleEditorChange}
                ref={quillRef}
                style={{ border: error ? '1px solid #d32f2f' : '1px solid #ccc', borderRadius: 4 }}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                modules={modules}
                formats={formats}
                className="my-quill-editor"
            />
            {error && (<FormHelperText style={{ color: '#d32f2f' }}>{helperText}</FormHelperText>)}
            {/* <textarea
                value={editorHtml}
                readOnly
                rows="10"
                style={{ width: '100%' }}
            /> */}
            {/* <div className="mt-4">
                <h3>Vista previa del contenido:</h3>
                <div 
                    className="content-preview"
                    dangerouslySetInnerHTML={{ __html: editorHtml }}
                />
            </div> */}
        </div>
    );
}

// Configura los módulos y formatos según tus necesidades
const modules = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Títulos
        ['bold', 'italic', 'underline', 'strike'], // Estilos de texto
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }, { 'background': [] },], // Listas
        [{ 'script': 'sub' }, { 'script': 'super' }], // Subíndice y superíndice
        [{ 'indent': '-1' }, { 'indent': '+1' }], // Sangrías
        [{ 'direction': 'rtl' }], // Dirección del texto
        [{ 'align': [] }], // Alineación

        ['link', 'image', 'video', 'formula'],// Enlaces, imágenes, videos
        ['blockquote', 'code-block'],
        ['clean'] // Limpiar formato
    ],
    imageResize: {
        handleStyles: {
            displaySize: true,
            backgroundColor: "black",
            border: "none",
            color: "white",
        },
        modules: ['Resize', 'DisplaySize'],
    },
};

const formats = [
    'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'color', 'background',
    'list', 'bullet', 'indent', 'align', 'script', 'direction', 'link', 'image', 'video', 'formula'
];

export default WYSIWYGEditor;